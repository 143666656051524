import { Pipe, PipeTransform } from '@angular/core';
import { InputCheckboxGroupValueType } from './input-checkbox-group.component';

@Pipe({
  name: 'checkboxIsChecked',
  standalone: true,
})
export class CheckboxIsCheckedPipe implements PipeTransform {
  transform(option: any, valueExpr?: string, values?: InputCheckboxGroupValueType): boolean {
    return (values || []).includes(valueExpr ? option[valueExpr] : option);
  }
}
