import { DatePipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';

const CURRENT_YEAR = `${new Date().getFullYear()}`;

@Injectable({
  providedIn: 'root',
})
export class FormatsService {
  private datePipe = inject(DatePipe);
  
  constructor() {}

  getFormattedDateTime(date: Date): string {
    let formattedDate = (this.datePipe.transform(date, 'dd MMM') || '').replace('.', '');

    if (this.datePipe.transform(date, 'YYYY') !== CURRENT_YEAR) {
      formattedDate += ` ${this.datePipe.transform(date, 'YYYY')}`;
    }
    formattedDate += ` - ${this.datePipe.transform(date, 'HH:mm')}`;

    return formattedDate;
  }

  getFormattedDateTimeLong(date: Date): string {
    return this.datePipe.transform(date, 'dd MMMM YYYY - HH:mm') + 'hs';
  }

  getFormattedTime(date: Date): string {
    return this.datePipe.transform(date, 'HH:mm') + 'hs';
  }
}
