import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { getCartItemDetailsText, getCartItemSubtotal } from '@pedix-workspace/orders';
import { CartItem, CartItemCategory, SUPPORTED_LANGUAGES } from '@pedix-workspace/utils';

import { CounterComponent } from '../counter/counter.component';

import { CartItemDetailsComponent } from '../cart-item-details/cart-item-details.component';

import { NgClass } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { ImageComponent } from '@pedix-workspace/angular-ui-image';
import { IconCloseComponent, IconNoteComponent } from '@pedix-workspace/angular-ui-icons';
import {
  ImageStoragePipe,
  PropInSelectedLanguagePipe,
} from '@pedix-workspace/pedixapp-shared-pipes';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { CartItemStockComponent } from '@pedix-workspace/pedixapp-stock';
import { ProductPriceComponent } from '../product-price/product-price.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-resume-item',
  templateUrl: './resume-item.component.html',
  styleUrls: ['./resume-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    CartItemDetailsComponent,
    CounterComponent,
    ImageComponent,
    IconCloseComponent,
    IconNoteComponent,
    ImageStoragePipe,
    PropInSelectedLanguagePipe,
    TagComponent,
    CartItemStockComponent,
    ProductPriceComponent,
  ],
})
export class ResumeItemComponent implements OnInit {
  @Input() cartItem: CartItem;
  @Input() editable = false;
  @Input() small = false;
  @Input() listIndex: number;
  @Input() selectedLanguage: SUPPORTED_LANGUAGES;
  @Input() displaySku: boolean;
  @Input() isCategoryAvailable = true;

  @Input() displayItemStockInfo = false;
  @Input() isOutOfStock = false;
  @Input() stockQty: CartItem['product']['stockQty'];

  @Output() edit = new EventEmitter<CartItem>();
  @Output() remove = new EventEmitter<CartItem>();
  @Output() update = new EventEmitter<CartItem>();
  @Output() categoryNotAvailable = new EventEmitter<CartItemCategory>();

  hasClickHandler = false;
  hasRemoveHandler = false;
  showProductQuantities = false;
  cartItemDetails: string;

  LAZY_LOAD_IMAGE_MIN_INDEX = 9;

  get cartItemSubtotal() {
    return getCartItemSubtotal(
      {
        product: this.cartItem.product,
        qty: this.cartItem.qty,
        selectedPresentation: this.cartItem.selectedPresentation,
        selectedOptions: this.cartItem.selectedOptions,
      },
      { usePriceDiscount: false },
    );
  }

  get cartItemSubtotalWithDiscount() {
    return getCartItemSubtotal(
      {
        product: this.cartItem.product,
        qty: this.cartItem.qty,
        selectedPresentation: this.cartItem.selectedPresentation,
        selectedOptions: this.cartItem.selectedOptions,
      },
      { usePriceDiscount: true },
    );
  }

  get productImage() {
    return this.cartItem.product.images[0];
  }

  ngOnInit() {
    this.hasClickHandler = this.edit.observers.length > 0;
    this.hasRemoveHandler = this.remove.observers.length > 0;
    this.showProductQuantities = !this.cartItem.product.presentations?.displayItemQuantities;
    this.cartItemDetails = getCartItemDetailsText(this.cartItem, {
      selectedLanguage: this.selectedLanguage,
      displaySku: this.displaySku,
    });
  }

  onEdit() {
    if (!this.isCategoryAvailable) {
      this.categoryNotAvailable.emit(this.cartItem.category);

      return;
    }
    if (this.isOutOfStock) {
      return;
    }
    this.edit.emit(this.cartItem);
  }

  onRemove(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.remove.emit(this.cartItem);
  }

  onClickCategoryNotAvailable(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.categoryNotAvailable.emit(this.cartItem.category);
  }

  onCountChanged(qty: number) {
    this.update.emit({
      ...this.cartItem,
      qty,
    });
  }
}
