import { Injectable } from '@angular/core';
import { ApiV1_Category, ApiV1_Product } from '@pedix-workspace/api-v1';
import { SearchIndex, createSearchIndex, getAllSearchEntries, searchInIndex } from './search-index';
import { SUPPORTED_LANGUAGES, getPropInSelectedLanguage } from '@pedix-workspace/utils';
import { combineLatest, map, Observable } from 'rxjs';

export type SearchResults = {
  category: ApiV1_Category;
  indexedProducts: {
    listIndex: number;
    product: ApiV1_Product;
  }[];
}[];

export type SearchIndexOptions = {
  selectedLanguage?: SUPPORTED_LANGUAGES;
  displaySku?: boolean;
};

@Injectable({ providedIn: 'root' })
export abstract class SearchResultsService {
  private searchIndex: SearchIndex<ApiV1_Product> | null;

  abstract getCategories(): Observable<ApiV1_Category[]>;
  abstract getProducts(): Observable<ApiV1_Product[]>;

  invalidateIndex() {
    this.searchIndex = null;
  }

  getSearchResults(searchTerm: string, options: SearchIndexOptions): Observable<ApiV1_Product[]> {
    return combineLatest([this.getCategories(), this.getProducts()]).pipe(
      map(([categories = [], products = []]) => {
        if (!this.searchIndex) {
          this.searchIndex = this.createSearchResultsIndex(categories, products, options);
        }
        if (!searchTerm) {
          return getAllSearchEntries(this.searchIndex);
        }

        return searchInIndex(this.searchIndex, searchTerm);
      }),
    );
  }

  getFilteredResults(products: ApiV1_Product[], filters: { productTagIds?: string[] } = {}) {
    if (!filters.productTagIds || filters.productTagIds.length === 0) {
      return products;
    }
    return products.filter(product => {
      if (product.linkedProductTagIds) {
        return product.linkedProductTagIds.some(tagId => filters.productTagIds?.includes(tagId));
      } else {
        return true;
      }
    });
  }

  private createSearchResultsIndex(
    categories: ApiV1_Category[],
    products: ApiV1_Product[],
    options: SearchIndexOptions,
  ): SearchIndex<ApiV1_Product> {
    const categoriesMap: Record<string, ApiV1_Category> = categories.reduce(
      (mapedCategories, category) =>
        Object.assign(mapedCategories, {
          [category.id]: category,
        }),
      {},
    );

    return createSearchIndex(products, {
      indexedKeywordsFn(entry) {
        const keywords = [
          getPropInSelectedLanguage(entry, 'name', options.selectedLanguage, true),
          getPropInSelectedLanguage(entry, 'groupName', options.selectedLanguage),
          getPropInSelectedLanguage(entry, 'description', options.selectedLanguage),
          getPropInSelectedLanguage(
            categoriesMap[entry.categoryId],
            'name',
            options.selectedLanguage,
            true,
          ),
          ...(entry.tags || []).map(productTag =>
            getPropInSelectedLanguage(productTag, 'tag', options.selectedLanguage, true),
          ),
        ];

        if (entry.presentations) {
          entry.presentations.items.forEach(item => {
            keywords.push(getPropInSelectedLanguage(item, 'name', options.selectedLanguage, true));
          });
        }

        if (options.displaySku && entry.sku) {
          keywords.push(entry.sku);
        }

        return keywords;
      },
    });
  }
}
