import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="payment"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconPaymentComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.5603 27.5938C28.2139 27.5938 27.8818 27.7271 27.6369 27.9644C27.392 28.2018 27.2545 28.5237 27.2545 28.8594C27.2545 29.195 27.392 29.517 27.6369 29.7543C27.8818 29.9917 28.2139 30.125 28.5603 30.125H33.7835C34.1298 30.125 34.4619 29.9917 34.7068 29.7543C34.9517 29.517 35.0893 29.195 35.0893 28.8594C35.0893 28.5237 34.9517 28.2018 34.7068 27.9644C34.4619 27.7271 34.1298 27.5938 33.7835 27.5938H28.5603ZM3.75 14.9375C3.75 13.5948 4.3003 12.3072 5.27984 11.3578C6.25939 10.4084 7.58793 9.875 8.97321 9.875H35.0893C36.4746 9.875 37.8031 10.4084 38.7827 11.3578C39.7622 12.3072 40.3125 13.5948 40.3125 14.9375V30.125C40.3125 31.4677 39.7622 32.7553 38.7827 33.7047C37.8031 34.6541 36.4746 35.1875 35.0893 35.1875H8.97321C7.58793 35.1875 6.25939 34.6541 5.27984 33.7047C4.3003 32.7553 3.75 31.4677 3.75 30.125V14.9375ZM37.7009 14.9375C37.7009 14.2662 37.4257 13.6223 36.936 13.1476C36.4462 12.6729 35.7819 12.4062 35.0893 12.4062H8.97321C8.28057 12.4062 7.6163 12.6729 7.12653 13.1476C6.63676 13.6223 6.36161 14.2662 6.36161 14.9375V17.4688H37.7009V14.9375ZM6.36161 30.125C6.36161 30.7963 6.63676 31.4402 7.12653 31.9149C7.6163 32.3896 8.28057 32.6562 8.97321 32.6562H35.0893C35.7819 32.6562 36.4462 32.3896 36.936 31.9149C37.4257 31.4402 37.7009 30.7963 37.7009 30.125V20H6.36161V30.125Z"
    />
  </svg>`,
})
export class IconPaymentComponent extends IconComponent {}
