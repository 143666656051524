/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  isHereMaps,
  isHereMapsWithMapEventsNamespace,
  isHereMapsWithServiceNamespace,
  isHereMapsWithUiNamespace,
} from 'here-maps-type-guards';
import { HMapEvent, HMapMarker } from '../here-maps.types';

export function isHNamespace(H: any) {
  return (
    isHereMaps(H) &&
    isHereMapsWithServiceNamespace(H) &&
    isHereMapsWithUiNamespace(H) &&
    isHereMapsWithMapEventsNamespace(H)
  );
}

export function isMapEvent(event: any): event is HMapEvent {
  return Boolean(event?.currentPointer?.viewportX && event?.currentPointer?.viewportY);
}

export function isMapMarker(marker: any): marker is HMapMarker {
  return typeof marker?.getGeometry === 'function';
}
