import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="minus-sign"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconMinusSignComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.93314 21.125H38.0669C38.314 21.125 38.4375 21.2566 38.4375 21.5197V24.4803C38.4375 24.7434 38.314 24.875 38.0669 24.875H6.93314C6.68605 24.875 6.5625 24.7434 6.5625 24.4803V21.5197C6.5625 21.2566 6.68605 21.125 6.93314 21.125Z"
    />
  </svg>`,
})
export class IconMinusSignComponent extends IconComponent {}
