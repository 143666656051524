@if (label) {
  <label
    class="ui-label ui-label--{{ labelSize }}"
    [ngClass]="{ 'ui-label--required': isRequired }"
    >{{ label }}</label
  >
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<div class="checkbox-options">
  @for (option of options; let i = $index; track option) {
    <div class="checkbox-item">
      <pxw-input-checkbox
        [name]="labelKey ? option[labelKey] : option"
        [ngModel]="option | checkboxIsChecked: valueKey : value"
        (ngModelChange)="onCheckboxChecked(option)"
        [legend]="labelKey ? option[labelKey] : option"
        [disabled]="option | checkboxIsDisabled: valueKey : disabledKeys"
      >
        <ng-template #itemTemplate>
          <ng-container
            *ngTemplateOutlet="
              parentItemTemplate || defaultItemTemplate;
              context: { $implicit: option, option: option, index: i }
            "
          ></ng-container>
        </ng-template>
      </pxw-input-checkbox>
    </div>
  }
</div>

<ng-template #defaultItemTemplate let-option>{{
  labelKey ? option[labelKey] : option
}}</ng-template>

@if (displaySelectAll) {
  <pxw-button
    class="ui-input__checkbox-group-select-all"
    [rounded]="true"
    [color]="isAllSelected ? 'pink' : 'blue'"
    size="sm"
    (click)="toggleSelectAll()"
  >
    @if (isAllSelected) {
      <pxw-icon icon="close" />
    } @else {
      <pxw-icon icon="check" />
    }
    {{ isAllSelected ? deselectAllText : selectAllText }}
  </pxw-button>
}

@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
  </div>
}

<ng-content></ng-content>
