import { MessageRenderParams, MessageRenderType } from '../order-message.types';

export type OrderMessageRendererTextModifiers = {
  bold?: boolean;
  italic?: boolean;
  monospace?: boolean;
  uppercase?: boolean;
  alignment?: 'left' | 'center' | 'right';
  indentLevel?: number;
};
export type OrderMessageRendererKeyValueParams = {
  keyText: string;
  keyModifiers?: OrderMessageRendererTextModifiers;
  valueText: string;
  valueModifiers?: OrderMessageRendererTextModifiers;
};
export type OrderMessageRendererNestedFn = () => void;

export abstract class OrderMessageRendererBase {
  constructor(protected renderParams: MessageRenderParams) {}

  abstract getIdentationByLevel(identationLevel: number): string;
  abstract getCurrencyOptions(): {
    forceDecimals: boolean;
    separateSymbol: boolean;
  };
  abstract getSystemMessageIcon(): string;
  abstract getType(): MessageRenderType;

  abstract renderLineBreak(): OrderMessageRendererBase;
  abstract renderEmptyLine(): OrderMessageRendererBase;
  abstract renderLine(
    text: string,
    modifiers?: OrderMessageRendererTextModifiers,
  ): OrderMessageRendererBase;
  abstract renderLine(fn: OrderMessageRendererNestedFn): OrderMessageRendererBase;
  abstract renderText(
    text: string,
    modifiers?: OrderMessageRendererTextModifiers,
  ): OrderMessageRendererBase;
  abstract renderKeyValueLine(params: OrderMessageRendererKeyValueParams): OrderMessageRendererBase;
  abstract renderSectionTitle(keyText: string, valueText: string): OrderMessageRendererBase;
  abstract renderObservations(
    observations: string,
    modifiers?: Pick<OrderMessageRendererTextModifiers, 'indentLevel'>,
  ): OrderMessageRendererBase;
  abstract renderSystemMessage(
    message: string,
    modifiers?: Pick<OrderMessageRendererTextModifiers, 'indentLevel'>,
  ): OrderMessageRendererBase;
  abstract renderColumns(
    columnValues: string[],
    columnSizes?: number[],
    modifiers?: OrderMessageRendererTextModifiers,
  ): OrderMessageRendererBase;
  abstract renderSeparator(): OrderMessageRendererBase;
  abstract output(): unknown;
}
