@if (label) {
  <label
    class="ui-label ui-label--{{ labelSize }}"
    [ngClass]="{ 'ui-label--required': isRequired }"
    >{{ label }}</label
  >
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<div class="radio-options">
  @for (option of options; let i = $index; track option) {
    <div class="radio-item">
      <div class="ui-input__editor">
        <div class="ui-input__radio">
          <input
            [name]="inputId + '-' + i"
            class="ui-input__radio-input"
            type="radio"
            [checked]="(valueKey ? option[valueKey] : option) === value"
            [value]="(valueKey ? option[valueKey] : option) === value ? 'on' : 'off'"
            [disabled]="isDisabled || option | radioIsDisabled: valueKey : disabledKeys"
            (change)="onOptionSelected(option)"
          />
        </div>
        <label
          (click)="onOptionSelected(option)"
          [attr.for]="inputId"
          class="ui-input__radio-legend"
          [ngClass]="{ 'ui-input__radio-legend--disabled': isDisabled }"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate || defaultItemTemplate;
              context: { $implicit: option, option: option, index: i }
            "
          ></ng-container>
        </label>
      </div>
    </div>
  }
</div>

<ng-template #defaultItemTemplate let-option>{{
  labelKey ? option[labelKey] : option
}}</ng-template>

@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
  </div>
}

<ng-content></ng-content>
