import { EscPosEncoder } from './esc-pos-encoder';
// import StarPrntEncoder from 'star-prnt-encoder';

export const getThermalPrinterEncoder = (language: 'esc-pos' | 'star-prnt', options: unknown) => {
  if (language === 'star-prnt') {
    throw new Error(
      'Not supported yet, check libs/web-printer/src/lib/thermal-printer-encoder.ts file',
    );
  }
  const encoder = new EscPosEncoder(options);

  return encoder;
};
