import { addMinutes, endOfDay, format, isWithinInterval, startOfDay } from 'date-fns';
import { TemporaryClosure, WorkingDays, TimeRange, Establishment } from "@pedix-workspace/utils";
import { Observable, SchedulerLike, of, timer } from 'rxjs';
// import { map, startWith } from 'rxjs/operators';

export type EstablishmentOpenedParams = {
  isOpened: boolean;
  isAboutToClose: boolean;
  isTemporaryClosed: boolean;
}

export type EstablishmentOpenedStatus = {
  isOpened: boolean;
  isAboutToClose: boolean;
  isTemporaryClosed: boolean;
  isClosed: boolean;
  isCartDisabled: boolean;
}

export type IsEstablishmentOpenedOptions = {
  timeOffsetInMinutes?: number;
  dateReference?: Date;
}

export const disableOrdersOnClosed = (workingDays?: WorkingDays) => {
  if (!workingDays || !workingDays.isActive) {
    return false;
  }
  return !workingDays.enableOrdersOnClosed;;
}

export const isValidWorkingHours = (workingHours?: TimeRange) => {
  return workingHours && workingHours.from && workingHours.to;
}

// export const isEstablishmentOpened$ = (workingDays: WorkingDays | undefined, temporaryClosure: TemporaryClosure | undefined, options: { isServer: boolean, scheduler?: SchedulerLike }): Observable<EstablishmentOpenedStatus> => {
//   if (options.isServer) {
//     // we cannot get server time accurately, as server time won't (necessarly) match client time
//     return of(getEstablishmentOpenedStatus({
//       isOpened: true,
//       isAboutToClose: false,
//       isTemporaryClosed: isEstablishmentTemporaryClosed(temporaryClosure),
//     }, workingDays));
//   }

//   const secondsToMinuteOClock = 60 - new Date().getSeconds();

//   return timer(secondsToMinuteOClock * 1000, 60000, options.scheduler)
//     .pipe(
//       map(() => getEstablishmentOpenedStatus({
//         isOpened: isEstablishmentOpened(workingDays),
//         isAboutToClose: isEstablishmentAboutToClose(workingDays),
//         isTemporaryClosed: isEstablishmentTemporaryClosed(temporaryClosure),
//       }, workingDays)),
//       startWith(getEstablishmentOpenedStatus({
//         isOpened: isEstablishmentOpened(workingDays),
//         isAboutToClose: isEstablishmentAboutToClose(workingDays),
//         isTemporaryClosed: isEstablishmentTemporaryClosed(temporaryClosure),
//       }, workingDays)),
//     );
// };

export const getEstablishmentOpenedStatus = ({ isOpened, isAboutToClose, isTemporaryClosed }: EstablishmentOpenedParams, workingDays: Establishment['workingDays']): EstablishmentOpenedStatus => {
  const isClosed = !isOpened;

  return {
    isOpened,
    isAboutToClose,
    isTemporaryClosed,
    isClosed: isClosed,
    isCartDisabled: isTemporaryClosed ? true : isClosed && disableOrdersOnClosed(workingDays),
  }
} 

export const isEstablishmentAboutToClose = (workingDays?: WorkingDays, timeOffsetInMinutes: number = 15) => {
  return isEstablishmentOpened(workingDays) && !isEstablishmentOpened(workingDays, { timeOffsetInMinutes });
}

export const isEstablishmentOpened = (workingDays?: WorkingDays, options: IsEstablishmentOpenedOptions = {}) => {
  if (!workingDays || !workingDays.isActive) {
    // If the establishment has this functionality turned off, then its always open
    return true;
  }
  const { selectedDaysOfWeek, customizeHoursByDay, daysOfWeekConfig } = workingDays;
  const { timeOffsetInMinutes = 0, dateReference = new Date() } = options;
  // Check by working day
  const dayOfWeek = <WorkingDays['selectedDaysOfWeek'][0]>Number(format(dateReference, 'i'));
  const previousDayOfWeek = <WorkingDays['selectedDaysOfWeek'][0]>(dayOfWeek === 1 ? 7 : dayOfWeek - 1);
  const nowWithOffset = addMinutes(dateReference, timeOffsetInMinutes);
  const time = Number(format(nowWithOffset, 'HHmm'));

  if (selectedDaysOfWeek.includes(dayOfWeek)) {

    // Check by primaryWorkingHours on current date
    const primaryWorkingHours = customizeHoursByDay
      ? daysOfWeekConfig[dayOfWeek].primaryWorkingHours
      : workingDays.primaryWorkingHours;
    const primaryWorkingHoursTo = primaryWorkingHours.to > 2400 ? 2400 : primaryWorkingHours.to;

    if (time >= primaryWorkingHours.from && time < primaryWorkingHoursTo) {
      return true;
    }

    // Check by secondaryWorkingHours on current date (if defined)
    const secondaryWorkingHours = customizeHoursByDay
      ? daysOfWeekConfig[dayOfWeek].secondaryWorkingHours
      : workingDays.secondaryWorkingHours;
    const secondaryWorkingHoursTo =
      secondaryWorkingHours.to > 2400 ? 2400 : secondaryWorkingHours.to;

    if (
      isValidWorkingHours(secondaryWorkingHours) &&
      time >= secondaryWorkingHours.from &&
      time < secondaryWorkingHoursTo
    ) {
      return true;
    }
  }

  // Check by hours on the date before (if applicable)
  // E.g. an stablishment is open between 18:00PM and 01:30AM on Friday and 10:00AM till 14:00PM on Saturday
  //  Hours that passes midnight are stored with a number higher than 2400, so this commerce is open from 1800 till 2530 on Saturdays.
  //  Now, let's suppose today is Saturday 01:00AM in the morning; this time is not between the workign hours for Saturday (1000 till 1400)
  //  so we are going to check against the last time interval of working hours of the day before (Friday).
  //  For doing this, we sum up 2400 to the current time and compare; in this example, 100 (1AM) + 2400 = 2500, which is between the [1800, 2530] range
  //  so we can conclude that the store is open
  if (selectedDaysOfWeek.includes(previousDayOfWeek)) {
    let pastDayWorkingHours: TimeRange | null;

    // We need to check against the last time interval of the day before, that's why we try to get secondaryWorkingHours first.
    //  If not defined, then use primaryWorkingHours
    if (customizeHoursByDay) {
      pastDayWorkingHours = isValidWorkingHours(
        daysOfWeekConfig[previousDayOfWeek].secondaryWorkingHours,
      )
        ? daysOfWeekConfig[previousDayOfWeek].secondaryWorkingHours
        : daysOfWeekConfig[previousDayOfWeek].primaryWorkingHours;
    } else {
      pastDayWorkingHours = isValidWorkingHours(workingDays.secondaryWorkingHours)
        ? workingDays.secondaryWorkingHours
        : workingDays.primaryWorkingHours;
    }

    // As stated before, hours past 24hs are stored as higher than 2400; we use current time + 2400 to compare against hours in previous day
    const timePast24Hours = time + 2400;

    if (timePast24Hours >= pastDayWorkingHours.from && timePast24Hours < pastDayWorkingHours.to) {
      return true;
    }
  }

  // Not open
  return false;
}

export const isEstablishmentTemporaryClosed = (temporaryClosure?: TemporaryClosure): boolean => {
  if (!temporaryClosure || temporaryClosure.mode === 'disabled') {
    return false;
  }
  if (temporaryClosure.mode === 'now') {
    return true;
  }

  return isWithinInterval(new Date(), {
    start: startOfDay(temporaryClosure.from),
    end: endOfDay(temporaryClosure.to),
  });
}