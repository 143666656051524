import { DeviceDetectorService, OS } from 'ngx-device-detector';
import { Injectable, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

export type WhatsappLinkParams = {
  text?: string;
  phone?: string;
};

@Injectable({
  providedIn: 'root',
})
export class WhatsappLinkService {
  private deviceService = inject(DeviceDetectorService);
  private sanitizer = inject(DomSanitizer);
  private document = inject(DOCUMENT);

  generateLink(params: WhatsappLinkParams) {
    let baseUrl = this.getBaseUrl();
    const queryParams = new URLSearchParams();

    if (params.text) {
      queryParams.set('text', params.text);
    }
    if (params.phone) {
      if (baseUrl === 'https://wa.me') {
        baseUrl += `/${params.phone}`;
      } else {
        queryParams.set('phone', params.phone);
      }
    }

    return `${baseUrl}?${queryParams.toString()}`;
  }

  getBaseUrl(): string {
    if (this.deviceService.isDesktop()) {
      return 'https://web.whatsapp.com/send';
    }
    if (this.deviceService.os === OS.ANDROID) {
      return 'whatsapp://send';
    }
    return 'https://wa.me';
  }

  getTrustedLink(params: WhatsappLinkParams) {
    const url = this.generateLink(params);

    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getWhatsappLinkHttpTarget() {
    return this.deviceService.isDesktop() ? '_blank' : '_self';
  }

  redirectToWhatsApp(params: WhatsappLinkParams) {
    const whatsappLink = this.document.createElement('A');

    const href = this.generateLink(params);

    whatsappLink.setAttribute('href', href);
    whatsappLink.setAttribute('target', this.getWhatsappLinkHttpTarget());

    this.document.body.append(whatsappLink);
    whatsappLink.click();
    this.document.body.removeChild(whatsappLink);
  }
}
