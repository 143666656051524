import { Establishment } from "./types";

export type EstablishmentUrlData = Pick<Establishment, 'slug' | 'customDomain' | 'subdomainStatus' | 'subdomainRedirection'>;

export const appEstablishmentUrl = (siteUrl: string, establishmentData: EstablishmentUrlData, pathParts?: string[]) => {
  const url = new URL(siteUrl);

  if (establishmentData.customDomain) {
    url.host = establishmentData.customDomain.domain;
  } else {
    const hostnameParts = url.hostname.split('.');
    const hasSubdomain = hostnameParts.length >= 3;
    const subdomainRedirectionEnabled = (establishmentData.subdomainStatus === 'ACTIVE' && establishmentData.subdomainRedirection) || false;

    if (hasSubdomain) {
      const subdomain = hostnameParts[0];

      url.host = url.host.replace(`${subdomain}.`, '');
    }
    
    if (subdomainRedirectionEnabled) {
      if (!url.host.startsWith(`${establishmentData.slug}.`)) {
        url.host = `${establishmentData.slug}.${url.host}`;
      }
    } else {
      url.pathname = `/${establishmentData.slug}`;
    }
  }
  
  if (pathParts) {
    if (url.pathname.slice(-1) !== '/') {
      url.pathname += '/';
    }
    url.pathname += `${pathParts.join('/')}`;
  }

  return url.toString();
};
