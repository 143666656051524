import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CartItem } from '@pedix-workspace/utils';
import { StockService } from '../stock.service';
import { StockInfo } from '@pedix-workspace/shared-stock';

import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { IconAlertComponent } from '@pedix-workspace/angular-ui-icons';

@UntilDestroy()
@Component({
  selector: 'pxw-cart-item-stock',
  templateUrl: './cart-item-stock.component.html',
  styleUrls: ['./cart-item-stock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TagComponent, IconAlertComponent],
})
export class CartItemStockComponent implements OnInit {
  @Input() cartItem: CartItem;
  @Input() productIsOutOfStock: boolean | null;

  itemsWithMissingStock: StockInfo[] = [];

  get productStockInfo(): StockInfo | undefined {
    return this.itemsWithMissingStock.find(stockInfo => stockInfo.item.type === 'product');
  }

  // This only applies for single-option presentations
  get presentationStockInfo(): StockInfo | undefined {
    if (
      this.cartItem.selectedPresentation &&
      !this.cartItem.selectedPresentation.option.displayItemQuantities
    ) {
      return this.presentationsWithMissingStock[0];
    }
    return undefined;
  }

  get presentationsWithMissingStock(): StockInfo[] {
    return this.itemsWithMissingStock.filter(stockInfo => stockInfo.item.type === 'presentation');
  }

  get hasPresentationsWithMissingStock(): boolean {
    return this.presentationsWithMissingStock.length > 0;
  }

  get hasOptionsWithMissingStock(): boolean {
    return (
      this.itemsWithMissingStock.filter(stockInfo => stockInfo.item.type === 'option-item').length >
      0
    );
  }

  private t = inject(TranslocoService);
  private stockService = inject(StockService);
  private cd = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.stockService.stock$.pipe(untilDestroyed(this)).subscribe(stock => {
      this.itemsWithMissingStock = stock
        .getStockInfoArrayByCartItem(this.cartItem.id)
        .filter(stockInfo => stockInfo.missingStock > 0);

      this.cd.markForCheck();
    });
  }

  get displayWarningMessage(): boolean {
    return this.productStockInfo?.hasMissingStock || this.itemsWithMissingStock.length > 0;
  }

  get message(): string {
    if (this.productIsOutOfStock) {
      return this.t.translate('product.fixWithoutStockIssuesCta');
    }
    if (this.presentationStockInfo) {
      if (this.presentationStockInfo.item.stockQty === 1) {
        return this.t.translate('product.justOneUnitAvailable');
      }
      return this.t.translate('product.justManyUnitsAvailable', {
        totalStock: this.presentationStockInfo.item.stockQty,
      });
    }
    if (this.hasPresentationsWithMissingStock || this.hasOptionsWithMissingStock) {
      return this.t.translate('product.fixStockIssuesCta');
    }
    if (this.productStockInfo?.hasMissingStock) {
      if (this.productStockInfo?.item.stockQty === 0) {
        return this.t.translate('product.fixWithoutStockIssuesCta');
      }
      if (this.productStockInfo?.item.stockQty === 1) {
        return this.t.translate('product.justOneUnitAvailable');
      }
      return this.t.translate('product.justManyUnitsAvailable', {
        totalStock: this.productStockInfo.item.stockQty,
      });
    }
    return '';
  }
}
