<ng-container *transloco="let t">
  <div
    class="product"
    [ngClass]="{ 'click-handler': hasClickHandler, small: small, 'without-stock': isOutOfStock }"
    (click)="onEdit()"
  >
    @if (productImage) {
      <figure class="product-image">
        <pxw-image
          [src]="productImage | imageStorage: { size: '250x250' }"
          [alt]="cartItem.product | propInSelectedLanguage: 'name' : selectedLanguage : true"
          [lazyLoad]="listIndex >= LAZY_LOAD_IMAGE_MIN_INDEX"
        ></pxw-image>
      </figure>
    }
    @if (!productImage) {
      <div class="product-image content-without-image">
        {{ t('product.withoutImage') }}
      </div>
    }
    <div class="product-content">
      <div class="product-heading">
        <div>
          @if (displaySku && cartItem.product.sku) {
            <p class="product-sku">{{ cartItem.product.sku }}</p>
          }
          <h2 class="product-title">
            {{ cartItem.product | propInSelectedLanguage: 'name' : selectedLanguage : true }}
          </h2>
        </div>
        @if (editable && hasRemoveHandler) {
          <pxw-icon
            icon="close"
            class="remove-item ui-clickable"
            (click)="onRemove($event)"
            size="xs"
          ></pxw-icon>
        }
      </div>
      <div class="product-body">
        <app-cart-item-details
          class="product-subtitle"
          [cartItem]="cartItem"
          [selectedLanguage]="selectedLanguage"
          [displaySku]="displaySku"
        ></app-cart-item-details>

        @if (cartItem.observations) {
          <div class="product-observations">
            <pxw-icon icon="note" size="xs"></pxw-icon>
            <div class="observations" [ngClass]="{ editable }">
              {{ cartItem.observations }}
            </div>
          </div>
        }
      </div>
      <div class="product-footer">
        @if (!isCategoryAvailable) {
          <pxw-tag
            class="not-available"
            size="xs"
            icon="clock"
            type="warning"
            (click)="onClickCategoryNotAvailable($event)"
            >{{ t('categoryAvailability.notAvailable') }}</pxw-tag
          >
        } @else {
          <div class="counter-wrapper">
            @if (showProductQuantities && editable && !isOutOfStock) {
              <pxw-counter
                [count]="cartItem.qty"
                (countChange)="onCountChanged($event)"
                size="sm"
                [inverted]="true"
                [max]="stockQty || undefined"
              ></pxw-counter>
            }
            @if (showProductQuantities && !editable) {
              <div class="product-qty">x{{ cartItem.qty }}</div>
            }
            @if (displayItemStockInfo) {
              <pxw-cart-item-stock
                [cartItem]="cartItem"
                [productIsOutOfStock]="isOutOfStock"
              ></pxw-cart-item-stock>
            }
          </div>
          <pxw-product-price
            [price]="cartItemSubtotal"
            [priceDiscount]="cartItemSubtotalWithDiscount"
            layout="resume-item"
          />
        }
      </div>
    </div>
  </div>
</ng-container>
