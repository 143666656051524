<div
  class="button decrement"
  [ngClass]="{ disabled: disabled || decrementDisabled || (min !== undefined && count <= min) }"
  (click)="decrement($event)"
>
  <pxw-icon class="icon minus" icon="minus-sign" [size]="iconSize"></pxw-icon>
</div>

<pxw-input-number
  name="counter"
  [ngModel]="count"
  [allowDecimals]="false"
  [min]="min"
  [max]="max"
  [disabled]="disabled || (decrementDisabled && incrementDisabled)"
  (ngModelChange)="onValueChange($event)"
  (click)="onCounterClick($event)"
/>

<div
  class="button increment"
  [ngClass]="{ disabled: disabled || incrementDisabled || (max !== undefined && count >= max) }"
  (click)="increment($event)"
>
  <pxw-icon class="icon plus" icon="plus-sign" [size]="iconSize"></pxw-icon>
</div>
