import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Renderer2, inject } from '@angular/core';
import { HNamespace, HPlatform } from './here-maps.types';
import { isHNamespace } from './utils/here-maps-type-guards';

type HereMapsResource = {
  type: 'style' | 'script';
  href: string;
};

@Injectable({ providedIn: 'root' })
export class HereMapsLoaderService {
  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private prefetchCalled = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private loadingPromise: Promise<HNamespace>;

  private namespaceRef: HNamespace;

  private resources: HereMapsResource[] = [
    { type: 'style', href: 'https://js.api.here.com/v3/3.1/mapsjs-ui.css' },
    { type: 'script', href: 'https://js.api.here.com/v3/3.1/mapsjs-core.js' },
    { type: 'script', href: 'https://js.api.here.com/v3/3.1/mapsjs-service.js' },
    { type: 'script', href: 'https://js.api.here.com/v3/3.1/mapsjs-ui.js' },
    { type: 'script', href: 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js' },
  ];

  async getNamespaceAndPlatform(apiKey: string, renderer: Renderer2) {
    if (!this.namespaceRef) {
      this.namespaceRef = await this.loadResources(renderer);
    }
    const platformRef = <HPlatform>new this.namespaceRef.service.Platform({
      apikey: apiKey,
    });
    return [this.namespaceRef, platformRef] as const;
  }

  prefetchResources() {
    if (this.prefetchCalled || !isPlatformBrowser(this.platformId)) {
      return;
    }
    this.resources.forEach(resource => {
      const link: HTMLLinkElement = this.document.createElement('link');

      link.rel = 'prefetch';
      link.as = resource.type;
      link.href = resource.href;

      this.document.head.appendChild(link);
    });
    this.prefetchCalled = true;
  }

  private loadResources(renderer: Renderer2) {
    if (!this.loadingPromise) {
      const promises = this.resources.map(resource => {
        return new Promise<void>((resolve, reject) => {
          let element: HTMLLinkElement | HTMLScriptElement;

          if (resource.type === 'script') {
            element = <HTMLScriptElement>this.document.createElement('script');

            element.src = resource.href;
            element.async = false;
            element.defer = true;
          } else {
            element = <HTMLLinkElement>this.document.createElement('link');

            element.href = resource.href;
            element.rel = 'stylesheet';
          }

          element.onload = () => {
            resolve();
          };
          element.onerror = () => {
            console.log('onerror');

            reject();
          };

          renderer.appendChild(this.document.head, element);
        });
      });

      this.loadingPromise = Promise.all(promises).then(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const H = (window as any)['H'];

        if (isHNamespace(H)) {
          return H;
        }
        return Promise.reject();
      });
    }

    return this.loadingPromise;
  }
}
