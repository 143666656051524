import { format } from 'date-fns';
import { Category } from './types';

export const DEFAULT_AVAILABILITY = {
  isActive: false,
  isHidden: false,
  daysOfWeek: [],
  timeRange: {},
};

export const isCategoryAvailable = (availability: Category['availability']) => {
  if (!availability) {
    return true;
  }
  const { daysOfWeek, timeRange, isActive } = availability;

  if (!isActive) {
    return true;
  }

  const dayOfWeek = Number(format(new Date(), 'i'));
  const isTodayAvailable = !daysOfWeek || daysOfWeek.length === 0 || daysOfWeek.includes(dayOfWeek);

  if (!isTodayAvailable) {
    return false;
  }

  const time = Number(format(new Date(), 'HHmm'));
  const isNowAvailable =
    !timeRange?.from || !timeRange?.to || (time >= timeRange.from && time < timeRange.to);

  if (!isNowAvailable) {
    return false;
  }

  return true;
};
