import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'pxwPriceDiscount',
  standalone: true,
})
export class PriceDiscountPipe implements PipeTransform {
  transform(originalPrice?: number, discountPrice?: number): number {
    if (originalPrice && discountPrice && discountPrice <= originalPrice) {
      const percentage = (discountPrice * 100) / originalPrice;

      return 100 - (percentage < 50 ? Math.ceil(percentage) : Math.floor(percentage));
    }
    return 0;
  }
}
