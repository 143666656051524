import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UiHereMapsConfigService {
  getSelectAddressText(): string {
    return 'Select your address from the list';
  }
  getConfirmAddressText(): string {
    return 'Please, validate your address';
  }
  getWrongAddressText(): string {
    return "Isn't this your address?";
  }
  getLocationSelectedOnMapText(): string {
    return 'Location selected on the map';
  }
  getLocateOnMapText(): string {
    return 'Locate your address on the map';
  }
  getChangeLocationText(): string {
    return 'Edit location';
  }
  getAddressNotFoundText(): string {
    return "We can't find your address";
  }
  getAllAddressesWrongText(): string {
    return 'Can’t find your address here?';
  }
  getGeolocationErrorText(): string {
    return 'Could not retrieve your position. Please ensure you allow location permision in your browser.';
  }
  getMapLocationPopupButton(): string {
    return 'Ready';
  }
  getMapLocationPopupInstructions(): string[] {
    return [];
  }
}
