export function translate(
  translations: Record<string, string>,
  messageKey: string,
  messageValues?: Record<string, any>,
): string {
  if (!translations[messageKey]) {
    return messageKey;
  }
  let text = translations[messageKey];

  if (messageValues) {
    Object.entries(messageValues).forEach(([key, value]) => {
      text = text.replace(new RegExp(`{{ *${key} *}}`, 'g'), value);
    });
  }
  return text;
}
