import { ElementRef, Pipe, PipeTransform, QueryList } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

@UntilDestroy()
@Pipe({
  name: 'pxwViewChildren',
  standalone: true,
})
export class ViewChildrenPipe implements PipeTransform {
  elements$ = new BehaviorSubject<ElementRef<HTMLElement>[]>([]);
  elementsObservable$ = this.elements$.asObservable();

  transform(value?: QueryList<ElementRef<HTMLElement>>): Observable<ElementRef<HTMLElement>[]> {
    if (value) {
      setTimeout(() => {
        this.elements$.next(value.toArray());
      });

      value.changes.pipe(untilDestroyed(this)).subscribe(() => {
        setTimeout(() => {
          this.elements$.next(value.toArray());
        });
      });
    }
    return this.elementsObservable$;
  }
}
