import { Pipe, PipeTransform } from '@angular/core';
import { InputCheckboxGroupValueType } from './input-checkbox-group.component';

@Pipe({
  name: 'checkboxIsDisabled',
  standalone: true,
})
export class CheckboxIsDisabledPipe implements PipeTransform {
  transform(
    option: any,
    valueExpr: string,
    disabledOptions?: InputCheckboxGroupValueType,
  ): boolean {
    return (disabledOptions || []).includes(option[valueExpr]);
  }
}
