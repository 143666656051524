import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModulePreloadManualStrategyService implements PreloadingStrategy {

  private modulePreloadByName: Record<string, Subject<void>> = {};

  // Stores each route preloader fn into a Record, to manually trigger preload using preloadModuleByName method
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preloadName']) {
      this.modulePreloadByName[route.data['preloadName']] = new Subject();

      return this.modulePreloadByName[route.data['preloadName']].asObservable()
        .pipe(map(() => {
          if (route.data) {
            route.data['isPreload'] = true;
          }
          fn();
        }));
    }
    return of(null);
  }

  preloadModuleByName(preloadName: string) {
    if (!this.modulePreloadByName[preloadName]) {
      console.warn('Module preload not found for preloadName:', preloadName);

      return;
    }
    this.modulePreloadByName[preloadName].next();
  }
}