import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="map-marker"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconMapMarkerComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5703 5C15.0757 5 9 11.0756 9 18.5703C9 21.0767 9.49059 23.6654 10.8984 25.5312L22.5703 41L34.2422 25.5312C35.5209 23.8365 36.1406 20.8406 36.1406 18.5703C36.1406 11.0756 30.065 5 22.5703 5ZM22.5703 12.8596C25.7238 12.8596 28.281 15.4168 28.281 18.5703C28.281 21.7238 25.7238 24.281 22.5703 24.281C19.4168 24.281 16.8596 21.7238 16.8596 18.5703C16.8596 15.4168 19.4168 12.8596 22.5703 12.8596Z"
    />
  </svg>`,
})
export class IconMapMarkerComponent extends IconComponent {}
