import { getThermalPrinterEncoder } from '@pedix-workspace/web-printer';

import {
  EscPosBuilder,
  EscPosPrinterParams,
  MessageRenderParams,
  MessageRenderType,
} from '../order-message.types';
import {
  OrderMessageRendererBase,
  OrderMessageRendererKeyValueParams,
  OrderMessageRendererNestedFn,
  OrderMessageRendererTextModifiers,
} from './order-message-renderer-base.class';

const INDENT_BY_LEVEL = {
  0: '',
  1: '  ',
  2: '    ',
};

export class OrderMessageRendererEscPos extends OrderMessageRendererBase {
  private builder: EscPosBuilder;

  constructor(
    protected renderParams: MessageRenderParams,
    private printerParams: EscPosPrinterParams,
  ) {
    super(renderParams);

    this.builder = getThermalPrinterEncoder(this.printerParams.language, {
      codepageMapping: this.printerParams.codepageMapping,
    }) as EscPosBuilder;

    this.builder.initialize().codepage(this.printerParams.codepage);
  }

  getType(): MessageRenderType {
    return 'escpos';
  }
  getIdentationByLevel(identationLevel: number): string {
    return INDENT_BY_LEVEL[identationLevel];
  }
  getCurrencyOptions() {
    return {
      forceDecimals: true,
      separateSymbol: false,
    };
  }
  getSystemMessageIcon(): string {
    return '>';
  }

  renderLineBreak() {
    this.builder.newline();

    return this;
  }
  renderEmptyLine() {
    this.builder.newline();

    return this;
  }
  renderLine(
    param1: string | OrderMessageRendererNestedFn,
    modifiers?: OrderMessageRendererTextModifiers,
  ) {
    if (typeof param1 === 'function') {
      param1();
    } else {
      this.renderText(param1, modifiers);
    }
    this.renderLineBreak();

    return this;
  }
  renderText(text: string, modifiers?: OrderMessageRendererTextModifiers) {
    if (modifiers?.bold !== undefined) {
      this.builder.bold(true);
    }
    if (modifiers?.italic !== undefined) {
      this.builder.italic(true);
    }
    if (modifiers?.alignment !== undefined) {
      this.builder.align(modifiers.alignment);
    }
    if (modifiers?.indentLevel !== undefined) {
      this.builder.text(INDENT_BY_LEVEL[modifiers.indentLevel]);
    }

    this.builder.text(modifiers?.uppercase ? text.toUpperCase() : text);

    if (modifiers?.bold !== undefined) {
      this.builder.bold(false);
    }
    if (modifiers?.italic !== undefined) {
      this.builder.italic(false);
    }
    if (modifiers?.alignment !== undefined) {
      this.builder.align('left');
    }
    return this;
  }
  renderKeyValueLine({
    keyText,
    keyModifiers,
    valueText,
    valueModifiers,
  }: OrderMessageRendererKeyValueParams) {
    this.renderText(`${keyText}:`, keyModifiers);
    this.renderText(' ');
    this.renderText(valueText, valueModifiers);
    this.renderLineBreak();

    return this;
  }
  renderSectionTitle(text: string) {
    this.renderLine(text, { bold: true, uppercase: true });

    return this;
  }
  renderObservations(
    observations: string,
    modifiers?: Pick<OrderMessageRendererTextModifiers, 'indentLevel'>,
  ): OrderMessageRendererBase {
    this.renderText('*: ', { indentLevel: modifiers?.indentLevel });
    this.renderText(`"${observations}"`, { italic: true });
    this.renderLineBreak();

    return this;
  }
  renderSystemMessage(
    message: string,
    modifiers?: Pick<OrderMessageRendererTextModifiers, 'indentLevel'>,
  ): OrderMessageRendererBase {
    this.renderText(`${this.getSystemMessageIcon()} `, { indentLevel: modifiers?.indentLevel });
    this.renderText(message, { italic: true });

    return this;
  }
  renderColumns(
    columnValues: string[],
    columnSizes?: number[],
    modifiers?: OrderMessageRendererTextModifiers,
  ): OrderMessageRendererBase {
    if (!columnSizes) {
      columnSizes = columnValues.map(value => value.length);
    }
    const fractionsTotal = columnSizes.reduce((accum, size) => accum + size, 0);
    const columnsLength = columnSizes.map(fraction => {
      return Math.floor(
        (((fraction * 100) / fractionsTotal) * this.printerParams.charactersPerLine) / 100,
      );
    });
    const calculatedTotalLength = columnsLength.reduce((accum, size) => accum + size, 0);

    for (
      let i = columnsLength.length - 1,
        n = this.printerParams.charactersPerLine - calculatedTotalLength;
      n > 0;
      n--, i--
    ) {
      columnsLength[i]++;
    }
    const output = columnValues.reduce((accum, value, i) => {
      const columnLength = columnsLength[i];

      if (i < columnsLength.length - 1) {
        return (
          accum + value.padEnd(columnLength, ' ').slice(0, columnLength - 1) + ''.padEnd(1, ' ')
        );
      } else {
        return accum + value.padStart(columnLength, ' ').slice(0, columnLength);
      }
    }, '');

    this.renderLine(output, modifiers);

    return this;
  }
  renderSeparator(): OrderMessageRendererBase {
    this.renderLine(''.padEnd(this.printerParams.charactersPerLine, '- '));

    return this;
  }
  output() {
    const endOfPrintLineBreaks = this.printerParams.endOfPrintLineBreaks || 2;

    for (let i = 0; i < endOfPrintLineBreaks; i++) {
      this.renderEmptyLine();
    }

    this.builder.cut('partial');

    return this.builder.encode();
  }
}
