export type SearchIndexOptions<T> = {
  indexedKeywordsFn: (entry: T) => string[];
};
export type SearchEntry<T> = {
  entry: T;
  normalizedKeywords: string[];
};
export type SearchIndex<T> = SearchEntry<T>[];

export function createSearchIndex<T>(
  searchEntries: T[],
  options: SearchIndexOptions<T>,
): SearchIndex<T> {
  const searchIndex: SearchIndex<T> = [];

  searchEntries.forEach(entry => {
    const rawKeywords = options.indexedKeywordsFn(entry);
    const normalizedKeywords = rawKeywords.map(keyword => normalizeKeyword(keyword));

    searchIndex.push({
      entry,
      normalizedKeywords,
    });
  });
  return searchIndex;
}

function normalizeKeyword(keyword: string) {
  if (typeof keyword !== 'string') {
    return '';
  }
  // Lowercase and remove non-standard characters
  return keyword
    .trim()
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function searchInIndex<T>(searchIndex: SearchIndex<T>, searchText: string): T[] {
  const normalizedSearchText = normalizeKeyword(searchText);
  const normalizedSearchParts = normalizedSearchText.split(' ');
  const results: T[] = [];

  searchIndex.forEach(searchEntry => {
    if (
      normalizedSearchParts.every(search =>
        searchEntry.normalizedKeywords.some(keyword => keyword.includes(search)),
      )
    ) {
      results.push(searchEntry.entry);
    }
  });

  return results;
}

export function getAllSearchEntries<T>(searchIndex: SearchIndex<T>): T[] {
  return searchIndex.map(searchEntry => searchEntry.entry);
}
