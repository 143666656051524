import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="clock"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconClockComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.9688 7.0625C13.9087 7.0625 6.5625 14.4087 6.5625 23.4688C6.5625 32.5288 13.9087 39.875 22.9688 39.875C32.0288 39.875 39.375 32.5288 39.375 23.4688C39.375 14.4087 32.0288 7.0625 22.9688 7.0625ZM22.9688 37.0918C15.4468 37.0918 9.3457 30.9907 9.3457 23.4688C9.3457 15.9468 15.4468 9.8457 22.9688 9.8457C30.4907 9.8457 36.5918 15.9468 36.5918 23.4688C36.5918 30.9907 30.4907 37.0918 22.9688 37.0918Z"
      />
      <path
        d="M29.8812 28.4332L24.789 24.4453V14.8719C24.789 14.7017 24.6604 14.5625 24.5033 14.5625H22.7857C22.6286 14.5625 22.5 14.7017 22.5 14.8719V25.5245C22.5 25.625 22.5429 25.7179 22.6178 25.7759L28.5242 30.4407C28.6528 30.5413 28.8313 30.5103 28.9242 30.375L29.9455 28.8664C30.0383 28.7233 30.0098 28.5299 29.8812 28.4332Z"
      />
    </svg>
  `,
})
export class IconClockComponent extends IconComponent {}
