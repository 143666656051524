import { CustomField, CustomFieldDetail, firestoreDateParser, isFirestoreTimestamp } from "@pedix-workspace/utils";
import { parse } from "date-fns";

export const parseCustomFieldValue = (value: CustomFieldDetail['value'], fieldType: CustomField['fieldType']) => {
  if (fieldType === 'date') {
    return parseCustomFieldDateValue(value);
  }
  return value;
}

export const parseCustomFieldDateValue = (value?: CustomFieldDetail['value']): Date | null => {
  if (typeof value === 'string') {
    if (value.trim() === '') {
      return null;
    }
    if (/^\d{4}\/\d{2}\/\d{2}$/.test(value)) {
      return parse(value, 'yyyy/MM/dd', new Date());
    }
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
      return parse(value, 'dd/MM/yyyy', new Date());
    }
    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      return parse(value, 'yyyy-MM-dd', new Date());
    }
    if (/^\d{2}-\d{2}-\d{4}$/.test(value)) {
      return parse(value, 'dd-MM-yyyy', new Date());
    }
  }
  if (typeof value === 'string' || typeof value === 'number') {
    const date = new Date(value);

    if (date.toString() === 'Invalid Date') {
      return null;
    } else {
      return date;
    }
  }
  if (isFirestoreTimestamp(value)) {
    return firestoreDateParser(value);
  }
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return undefined;
  }
  if (value instanceof Date) {
    return value;
  }
  
  return null;
}