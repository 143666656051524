import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="coupons"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconCouponsComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3692 31.0769L30.7538 15.6923M3 35.6923C3 36.5073 3.32333 37.289 3.89903 37.8658C4.47474 38.4427 5.25579 38.7676 6.07077 38.7692H39.9292C40.7442 38.7676 41.5253 38.4427 42.101 37.8658C42.6767 37.289 43 36.5073 43 35.6923V29.4338C41.6715 29.0736 40.4985 28.286 39.6621 27.1927C38.8258 26.0994 38.3727 24.7611 38.3727 23.3846C38.3727 22.0081 38.8258 20.6699 39.6621 19.5766C40.4985 18.4832 41.6715 17.6956 43 17.3354V11.0769C43 10.2619 42.6767 9.48024 42.101 8.90338C41.5253 8.32652 40.7442 8.00163 39.9292 8H6.07077C5.25579 8.00163 4.47474 8.32652 3.89903 8.90338C3.32333 9.48024 3 10.2619 3 11.0769V17.3231C4.33914 17.6753 5.52394 18.461 6.36941 19.5576C7.21488 20.6542 7.67342 21.9999 7.67342 23.3846C7.67342 24.7693 7.21488 26.115 6.36941 27.2116C5.52394 28.3082 4.33914 29.0939 3 29.4462V35.6923Z"
        stroke="#FF7C7C"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.9066 18.7692C17.3147 18.7692 17.706 18.6071 17.9945 18.3186C18.283 18.0301 18.4451 17.6388 18.4451 17.2308C18.4451 16.8227 18.283 16.4314 17.9945 16.1429C17.706 15.8544 17.3147 15.6923 16.9066 15.6923C16.4986 15.6923 16.1073 15.8544 15.8188 16.1429C15.5303 16.4314 15.3682 16.8227 15.3682 17.2308C15.3682 17.6388 15.5303 18.0301 15.8188 18.3186C16.1073 18.6071 16.4986 18.7692 16.9066 18.7692ZM29.2143 31.0769C29.6223 31.0769 30.0137 30.9148 30.3022 30.6263C30.5907 30.3378 30.7528 29.9465 30.7528 29.5385C30.7528 29.1304 30.5907 28.7391 30.3022 28.4506C30.0137 28.1621 29.6223 28 29.2143 28C28.8063 28 28.415 28.1621 28.1265 28.4506C27.8379 28.7391 27.6759 29.1304 27.6759 29.5385C27.6759 29.9465 27.8379 30.3378 28.1265 30.6263C28.415 30.9148 28.8063 31.0769 29.2143 31.0769Z"
        stroke="#FF7C7C"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class IconCouponsComponent extends IconComponent {}
