import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { isProductWithoutStock } from '@pedix-workspace/shared-models';
import { Product } from '@pedix-workspace/utils';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pxwSortProductsByStock',
  standalone: true,
})
export class SortProductsByStockPipe<T extends Pick<Product, 'stockQty' | 'presentations'>>
  implements PipeTransform
{
  transform(products: T[]): T[] {
    return products.sort((productA, productB) => {
      if (
        isProductWithoutStock({
          stockQty: productA.stockQty,
          presentationItems: productA?.presentations?.items,
        })
      ) {
        return 1;
      }
      return isProductWithoutStock({
        stockQty: productB.stockQty,
        presentationItems: productB?.presentations?.items,
      })
        ? -1
        : 0;
    });
  }
}
