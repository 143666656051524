import { marker as _ } from '@ngneat/transloco-keys-manager/marker.js';
import {
  OrderItem,
  OrderPresentationParams,
  OrderOptionParams,
  MessageEstablishmentData,
} from '../order-message.types';
import {
  OrderHeadingParams,
  OrderItemsByCategories,
  OrderTotalParams,
  MessageRenderParams,
} from '../order-message.types';
import {
  translate,
  currencyFormat,
  getCurrencyLocaleDataByCountryCode,
  PaymentMethodType,
} from '@pedix-workspace/utils';
import {
  endOrderToOrderHeadingParams,
  endOrderToOrderItemsByCategories,
  getEndOrderToOrderTotalParams,
  hasAnyPriceModifier,
} from '../order-message-utils/order-message-utils';
import { OrderMessageRendererEscPos } from '../order-message-renderer/order-message-renderer-escpos.class';
import { OrderMessageRendererBase } from '../order-message-renderer/order-message-renderer-base.class';
import * as dateFnsTz from 'date-fns-tz';
import { OrderMessageRendererWhatsapp } from '../order-message-renderer/order-message-renderer-whatsapp.class';
import { OrderMessageRendererHtml } from '../order-message-renderer/order-message-renderer-html.class';
import { GenerateOrderMessageParams } from '../generate-order-message';

export function generateOrderMessageDetailed({
  rendererType,
  orderDetails,
  establishmentData,
  renderParams,
  printerParams,
}: GenerateOrderMessageParams) {
  const orderHeadingParams = endOrderToOrderHeadingParams(orderDetails, renderParams.siteUrl);
  const orderItemsByCategories = endOrderToOrderItemsByCategories(orderDetails.cartItems);
  const orderTotalParams = getEndOrderToOrderTotalParams(orderDetails);

  let renderer: OrderMessageRendererBase;

  switch (rendererType) {
    case 'whatsapp':
      renderer = new OrderMessageRendererWhatsapp(renderParams);
      break;
    case 'html':
      renderer = new OrderMessageRendererHtml(renderParams);
      break;
    case 'escpos':
      renderer = new OrderMessageRendererEscPos(renderParams, printerParams);
      break;
  }

  if (renderer.getType() === 'whatsapp') {
    renderOrderIntroMessage(renderer, undefined, renderParams);
    renderEmptyLine(renderer);
  }
  renderOrderHeading(renderer, { ...orderHeadingParams, establishmentData }, renderParams);
  renderEmptyLine(renderer);

  if (renderer.getType() === 'whatsapp') {
    renderProductIntroMessage(renderer, undefined, renderParams);
    renderEmptyLine(renderer);
  }

  renderOrderItems(renderer, orderItemsByCategories, renderParams);
  renderEmptyLine(renderer);
  renderOrderTotal(renderer, orderTotalParams, renderParams);

  if (renderer.getType() === 'whatsapp') {
    renderEmptyLine(renderer);
    renderEndOrderMessage(renderer, undefined, renderParams);
  }

  return renderer.output();
}

type NodeRenderer<T = unknown, R = MessageRenderParams> = {
  (renderer: OrderMessageRendererBase, options?: T, renderOptions?: R): void;
};

export const renderEmptyLine: NodeRenderer = renderer => renderer.renderEmptyLine();

export const renderOrderIntroMessage: NodeRenderer = (renderer, _params, { translations }) => {
  const orderIntro = translate(translations, _('orderMessage.orderIntroTitle'));

  renderer.renderLine(orderIntro, { italic: true });
};

export const renderProductIntroMessage: NodeRenderer = (renderer, _params, { translations }) => {
  const productIntro = translate(translations, _('orderMessage.productIntroTitle'));

  renderer.renderLine(productIntro, { italic: true });
};

export const renderEndOrderMessage: NodeRenderer = (renderer, _params, { translations }) => {
  const endOrderMessage = translate(translations, _('orderMessage.closingTitle'));

  renderer.renderLine(endOrderMessage, { italic: true });
};

export const renderOrderHeading: NodeRenderer<
  OrderHeadingParams & { establishmentData: MessageEstablishmentData }
> = (
  renderer,
  {
    id,
    sequenceId,
    date,
    name,
    phone,
    paymentMethod,
    paymentMethodType,
    couponCode,
    finalAmount,
    paymentMethodHowMuch,
    paymentLink,
    paymentLegend,
    shippingOptionType,
    address,
    addressReferences,
    mapUrl,
    tableNumber,
    customFieldDetails,
    establishmentData,
  },
  { translations, currencyCode, timezone },
) => {
  const formattedDate = timezone
    ? dateFnsTz.formatInTimeZone(date, timezone, 'dd/MM/yy - HH:mm')
    : dateFnsTz.format(date, 'dd/MM/yy - HH:mm');

  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.orderTitle')),
    keyModifiers: { bold: true },
    valueText: `#${sequenceId} / ${id}`,
    valueModifiers: { monospace: true },
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.orderDate')),
    keyModifiers: { bold: true },
    valueText: `${formattedDate}hs`,
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.nameTitle')),
    keyModifiers: { bold: true },
    valueText: name,
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.phoneTitle')),
    keyModifiers: { bold: true },
    valueText: phone,
  });

  renderer.renderEmptyLine();

  if (paymentMethod) {
    const paymentMethodLabel = translate(translations, _('orderMessage.paymentMethodTitle'));

    renderer.renderKeyValueLine({
      keyText: paymentMethodLabel,
      keyModifiers: { bold: true },
      valueText: paymentMethod,
    });
  }
  if (paymentLink && renderer.getType() === 'whatsapp') {
    const paymentLinkLabel = translate(translations, _('orderMessage.paymentLinkTitle'));

    renderer.renderKeyValueLine({
      keyText: paymentLinkLabel,
      keyModifiers: { bold: true },
      valueText: paymentLink,
    });
  }
  if (couponCode) {
    const couponCodeLabel = translate(translations, _('orderMessage.couponCodeTitle'));

    renderer.renderKeyValueLine({
      keyText: couponCodeLabel,
      keyModifiers: { bold: true },
      valueText: couponCode,
    });
  }

  const finalAmountLabel = translate(translations, _('orderMessage.finalAmountTitle'));
  const finalAmountValue = currencyFormat(
    finalAmount,
    getCurrencyLocaleDataByCountryCode(currencyCode),
    renderer.getCurrencyOptions(),
  );

  renderer.renderKeyValueLine({
    keyText: finalAmountLabel,
    keyModifiers: { bold: true },
    valueText: finalAmountValue,
  });

  if (paymentMethodType === PaymentMethodType.CASH && paymentMethodHowMuch) {
    const howMuchLabel = translate(translations, _('orderMessage.paymentHowMuchTitle'));
    const howMuchValue = currencyFormat(
      paymentMethodHowMuch,
      getCurrencyLocaleDataByCountryCode(currencyCode),
      renderer.getCurrencyOptions(),
    );

    renderer.renderKeyValueLine({
      keyText: howMuchLabel,
      keyModifiers: { bold: true },
      valueText: howMuchValue,
    });
  }

  if (paymentLegend && renderer.getType() === 'whatsapp') {
    const paymentLegendLines = paymentLegend.split(/\n|\r/);

    paymentLegendLines.forEach(line => renderer.renderSystemMessage(line));
  }

  renderer.renderEmptyLine();

  if (shippingOptionType) {
    if (shippingOptionType === 'DELIVERY' && address) {
      const shippingDeliveryLabel = translate(
        translations,
        _('orderMessage.shippingDeliveryTitle'),
      );
      const shippingDeliveryValue = translate(
        translations,
        _('orderMessage.shippingDeliveryValue'),
      );
      const addressLabel = translate(translations, _('orderMessage.addressTitle'));

      renderer.renderKeyValueLine({
        keyText: shippingDeliveryLabel,
        keyModifiers: { bold: true },
        valueText: shippingDeliveryValue,
      });
      renderer.renderKeyValueLine({
        keyText: addressLabel,
        keyModifiers: { bold: true },
        valueText: address,
      });

      if (addressReferences) {
        const addressReferencesLabel = translate(translations, _('orderMessage.referencesTitle'));

        renderer.renderKeyValueLine({
          keyText: addressReferencesLabel,
          keyModifiers: { bold: true },
          valueText: addressReferences,
        });
      }
      if (mapUrl && renderer.getType() === 'whatsapp') {
        const mapLabel = translate(translations, _('orderMessage.shippingLocationTitle'));

        renderer.renderKeyValueLine({
          keyText: mapLabel,
          keyModifiers: { bold: true },
          valueText: mapUrl,
        });
      }
    }

    if (shippingOptionType === 'TAKE_AWAY') {
      const takeAwayLabel = translate(translations, _('orderMessage.shippingTakeAwayTitle'));
      const takeAwayValue =
        establishmentData?.addressDetails?.freeSearch && renderer.getType() === 'whatsapp'
          ? translate(translations, _('orderMessage.shippingTakeAwayValueWithAddress'), {
              address: establishmentData.addressDetails.freeSearch,
            })
          : translate(translations, _('orderMessage.shippingTakeAwayValue'));

      renderer.renderKeyValueLine({
        keyText: takeAwayLabel,
        keyModifiers: { bold: true },
        valueText: takeAwayValue,
      });
    }

    if (shippingOptionType === 'IN_PLACE' && tableNumber) {
      const inPlaceLabel = translate(translations, _('orderMessage.shippingInPlaceTitle'));
      const inPlaceValue = translate(translations, _('orderMessage.shippingInPlaceValue'), {
        tableNumber,
      });

      renderer.renderKeyValueLine({
        keyText: inPlaceLabel,
        keyModifiers: { bold: true },
        valueText: inPlaceValue,
      });
    }
  }

  if (customFieldDetails?.length > 0) {
    renderer.renderEmptyLine();

    customFieldDetails.forEach(customFieldDetail => {
      renderer.renderKeyValueLine({
        keyText: customFieldDetail.name,
        keyModifiers: { bold: true },
        valueText: customFieldDetail.value,
      });
    });
  }
};

export const renderOrderItems: NodeRenderer<OrderItemsByCategories> = (
  renderer,
  { categories },
  renderOptions,
) => {
  categories.forEach(category => {
    const isLastCategory = category === categories[categories.length - 1];

    category.groups.forEach(categoryGroup => {
      const isLastGroup = categoryGroup === category.groups[category.groups.length - 1];

      renderCategory(
        renderer,
        {
          categoryName: category.categoryName,
          groupName: categoryGroup.isCustomGroup ? categoryGroup.name : undefined,
          products: categoryGroup.items,
        },
        renderOptions,
      );
      if (!isLastCategory || !isLastGroup) {
        renderEmptyLine(renderer);
      }
    });
  });
};

export const renderCategory: NodeRenderer<{
  categoryName: string;
  groupName: string;
  products: OrderItem[];
}> = (renderer, { categoryName, groupName, products }, renderOptions) => {
  const title = groupName ? `${categoryName} (${groupName})` : categoryName;

  renderer.renderLine(title, { bold: true });

  products.forEach(product => renderProduct(renderer, product, renderOptions));
};

export const renderProduct: NodeRenderer<OrderItem> = (
  renderer,
  {
    productName,
    presentationName,
    productOrPresentationSku,
    quantity,
    total,
    observations,
    presentations,
    options,
  },
  renderOptions,
) => {
  renderer.renderLine(() => {
    if (quantity && !presentations) {
      renderer.renderText(`${quantity}x `);
    }
    if (productOrPresentationSku && renderOptions.displaySku) {
      renderer.renderText(productOrPresentationSku, { bold: true });
      renderer.renderText(' | ');
    }
    if (presentationName) {
      renderer.renderText(`${productName} (${presentationName}): `);
    } else {
      renderer.renderText(`${productName}: `);
    }
    if (total) {
      renderer.renderText(
        currencyFormat(
          total,
          getCurrencyLocaleDataByCountryCode(renderOptions.currencyCode),
          renderer.getCurrencyOptions(),
        ),
      );
    }
  });

  if (presentations && presentations?.items.length > 0) {
    renderProductPresentations(renderer, presentations, renderOptions);
  }
  if (options && options.length > 0) {
    renderProductOptions(renderer, options, { ...renderOptions, nestingLevel: 1 });
  }
  if (observations) {
    renderProductObservations(renderer, { observations }, { ...renderOptions, nestingLevel: 1 });
  }
};

export const renderProductPresentations: NodeRenderer<OrderPresentationParams> = (
  renderer,
  presentations,
  { currencyCode, displaySku },
) => {
  presentations.items.forEach(presentation => {
    const formattedPrice = `${currencyFormat(presentation.price, getCurrencyLocaleDataByCountryCode(currencyCode), renderer.getCurrencyOptions())}`;

    renderer.renderText(`${presentation.quantity}x `);

    if (presentation.sku && displaySku) {
      renderer.renderText(presentation.sku, { bold: true });
      renderer.renderText(' | ');
    }
    renderer.renderText(`${presentation.name}: `);
    renderer.renderText(formattedPrice);

    renderer.renderEmptyLine();
  });
};

export const renderProductOptions: NodeRenderer<OrderOptionParams[]> = (
  renderer,
  options,
  { nestingLevel, currencyCode },
) => {
  options.forEach(option => {
    const optionTitle = `${renderer.getIdentationByLevel(nestingLevel)}${option.name}:`;

    renderer.renderLine(optionTitle);

    option.items.forEach(item => {
      let optionItemOutput = `${renderer.getIdentationByLevel(nestingLevel)}`;
      optionItemOutput += `- ${item.quantity}x ${item.name}`;

      if (item.price) {
        const formattedPrice = `${currencyFormat(item.price, getCurrencyLocaleDataByCountryCode(currencyCode), renderer.getCurrencyOptions())}`;

        optionItemOutput += `: ${formattedPrice}`;
      }

      renderer.renderLine(optionItemOutput);
    });
  });
};

export const renderProductObservations: NodeRenderer<{
  observations: string;
}> = (renderer, { observations }, { nestingLevel }) => {
  renderer.renderObservations(observations, { indentLevel: nestingLevel });
};

export const renderOrderTotal: NodeRenderer<OrderTotalParams> = (
  renderer,
  {
    totalAmount,
    finalAmount,
    totalProductDiscount,
    couponDiscount,
    deliveryCost,
    outOfDeliveryZone,
    extraChargeAmount,
    discountAmount,
    foodBankDonationAmount,
  },
  { translations, currencyCode },
) => {
  if (
    hasAnyPriceModifier({
      totalProductDiscount,
      couponDiscount,
      deliveryCost,
      extraChargeAmount,
      discountAmount,
      foodBankDonationAmount,
    })
  ) {
    const subtotalLabel = translate(translations, _('orderMessage.subtotalTitle'));
    const subtotalValue = currencyFormat(
      totalAmount,
      getCurrencyLocaleDataByCountryCode(currencyCode),
      renderer.getCurrencyOptions(),
    );

    renderer.renderKeyValueLine({
      keyText: subtotalLabel,
      valueText: subtotalValue,
    });

    if (totalProductDiscount) {
      const totalProductDiscountLabel = translate(
        translations,
        _('orderMessage.totalProductDiscountTitle'),
      );
      const totalProductDiscountValue = currencyFormat(
        totalProductDiscount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderKeyValueLine({
        keyText: totalProductDiscountLabel,
        valueText: `-${totalProductDiscountValue}`,
      });
    }

    if (couponDiscount) {
      const couponDiscountLabel = translate(translations, _('orderMessage.couponDiscountTitle'));
      const couponDiscountValue = currencyFormat(
        couponDiscount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderKeyValueLine({
        keyText: couponDiscountLabel,
        valueText: `-${couponDiscountValue}`,
      });
    }

    if (deliveryCost && !outOfDeliveryZone) {
      const deliveryCostLabel = translate(translations, _('orderMessage.deliveryCostTitle'));
      const deliveryCostValue = currencyFormat(
        deliveryCost,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderKeyValueLine({
        keyText: deliveryCostLabel,
        valueText: `+${deliveryCostValue}`,
      });
    }

    if (extraChargeAmount) {
      const extraChargeLabel = translate(translations, _('orderMessage.paymentMethodExtraTitle'));
      const extraChargeValue = currencyFormat(
        extraChargeAmount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderKeyValueLine({
        keyText: extraChargeLabel,
        valueText: `+${extraChargeValue}`,
      });
    }

    if (discountAmount) {
      const discountLabel = translate(translations, _('orderMessage.paymentMethodDiscountTitle'));
      const discountValue = currencyFormat(
        discountAmount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderKeyValueLine({
        keyText: discountLabel,
        valueText: `-${discountValue}`,
      });
    }

    // Food Bank Donation
    if (foodBankDonationAmount) {
      const foodBankDonationLabel = 'Donación Banco de Alimentos Córdoba';
      const foodBankDonationValue = currencyFormat(
        foodBankDonationAmount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderKeyValueLine({
        keyText: foodBankDonationLabel,
        valueText: `+${foodBankDonationValue}`,
      });
    }
  }
  const finalAmountLabel = translate(translations, _('orderMessage.finalAmountTitle'));
  const finalAmountValue = currencyFormat(
    finalAmount,
    getCurrencyLocaleDataByCountryCode(currencyCode),
    renderer.getCurrencyOptions(),
  );
  const finalAmountClarification = outOfDeliveryZone
    ? translate(translations, _('orderMessage.deliveryCostClarificationTitle'))
    : '';

  renderer.renderKeyValueLine({
    keyText: finalAmountLabel,
    keyModifiers: { bold: true, uppercase: true },
    valueText: `${finalAmountValue}${finalAmountClarification ? ' *' : ''}`,
    valueModifiers: { bold: true },
  });

  if (finalAmountClarification) {
    renderer.renderSystemMessage(finalAmountClarification);
  }
};
