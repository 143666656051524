import { Injectable, inject } from '@angular/core';
import { Firestore, collection, doc, getDoc } from '@angular/fire/firestore';
import { EstablishmentCheckout } from '@pedix-workspace/utils';
import { getEstablishmentCheckoutConverter } from '@pedix-workspace/shared-models';
import { AppRequestContextService } from '../../app-request-context.service';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentsCheckoutService {
  private firestore = inject(Firestore);
  private appRequestContextService = inject(AppRequestContextService);

  checkoutData: EstablishmentCheckout | null = null;

  get collectionRef() {
    return collection(this.firestore, 'establishmentsCheckout').withConverter(
      getEstablishmentCheckoutConverter({
        features: this.appRequestContextService.establishment.features,
      }),
    );
  }

  async get(establishmentId: string): Promise<EstablishmentCheckout> {
    if (!this.checkoutData) {
      this.checkoutData = await getDoc(doc(this.collectionRef, establishmentId)).then(
        documentSnapshot => documentSnapshot.data(),
      );
    }

    return this.checkoutData;
  }
}
