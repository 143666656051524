import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CartItemDetailsItems, getCartItemDetailsItems } from '@pedix-workspace/orders';
import { CartItem, SUPPORTED_LANGUAGES } from '@pedix-workspace/utils';

import { TranslocoDirective } from '@ngneat/transloco';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-cart-item-details',
    templateUrl: './cart-item-details.component.html',
    styleUrls: ['./cart-item-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    TranslocoDirective
],
})
export class CartItemDetailsComponent {
  
  @Input() cartItem: CartItem;
  @Input() selectedLanguage: SUPPORTED_LANGUAGES;
  @Input() displaySku = false;

  get cartItemDetailsItems(): CartItemDetailsItems[] {
    const cartItemDetailsItems: CartItemDetailsItems[] = [];

    if (this.cartItem.selectedPresentation) {
      const detailItems = getCartItemDetailsItems(this.cartItem.selectedPresentation.items, undefined, this.selectedLanguage, this.displaySku);
  
      cartItemDetailsItems.push(detailItems);
    }
    if (this.cartItem.selectedOptions) {
      this.cartItem.selectedOptions.forEach(({ option, items }) => {  
        const detailItems = getCartItemDetailsItems(items, option, this.selectedLanguage, this.displaySku);
  
        cartItemDetailsItems.push(detailItems);
      });
    }

    return cartItemDetailsItems;
  }
}
