import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { EnvironmentService } from '@pedix-workspace/angular-utils';
import { CdnImageOptions, getImageCdnUrl } from '@pedix-workspace/utils';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'imageStorage',
  standalone: true,
})
export class ImageStoragePipe implements PipeTransform {
  private environmentService = inject(EnvironmentService);
  
  constructor() {}

  transform(imageUrl?: string, options: CdnImageOptions = {}): string {
    if (!imageUrl) {
      return '';
    }

    return getImageCdnUrl(imageUrl, options, this.environmentService.env);
  }
}
