import { Injectable } from '@angular/core';
import { ModalHistoryComponent } from './modal-history.component';

@Injectable({ providedIn: 'root' })
export class ModalHistoryService {
  instances: ModalHistoryComponent[] = [];

  get activeInstance() {
    return this.instances[this.instances.length - 1];
  }

  isActiveInstance(modalHistory: ModalHistoryComponent) {
    return this.activeInstance === modalHistory;
  }

  push(modalHistory: ModalHistoryComponent) {
    this.instances.push(modalHistory);
  }

  pop() {
    this.instances.pop();
  }
}
