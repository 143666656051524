"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.marker = void 0;
function marker(key) {
  return key;
}
exports.marker = marker;
