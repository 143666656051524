import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { PaymentMethod, PaymentMethodType } from '@pedix-workspace/utils';

@Injectable({
  providedIn: 'root',
})
export class InitialDataService {
  private t = inject(TranslocoService);

  constructor() {}

  get initialPaymentMethods(): PaymentMethod[] {
    return [
      {
        removable: false,
        name: this.t.translate('global.paymentMethodCash'),
        active: true,
        type: PaymentMethodType.CASH,
        priceModifier: 'none',
      },
      {
        removable: false,
        name: this.t.translate('global.paymentMethodCard'),
        active: true,
        type: PaymentMethodType.CARD,
        priceModifier: 'none',
      },
      {
        removable: false,
        name: this.t.translate('global.paymentMethodMercadoPagoLink'),
        active: false,
        type: PaymentMethodType.MERCADOPAGO_LINK,
        legend: this.t.translate('global.paymentMethodLinkLegend'),
        priceModifier: 'none',
        hasLink: true
      },
      {
        removable: false,
        name: this.t.translate('global.paymentMethodUalaLink'),
        active: false,
        type: PaymentMethodType.UALA_LINK,
        legend: this.t.translate('global.paymentMethodLinkLegend'),
        priceModifier: 'none',
        hasLink: true
      },
      {
        removable: false,
        name: this.t.translate('global.paymentMethodCardLink'),
        active: false,
        type: PaymentMethodType.ZENRISE_LINK,
        legend: this.t.translate('global.paymentMethodLinkLegend'),
        priceModifier: 'none',
        hasLink: true,
        countryAvailables: ['AR']
      },
    ];
  }
}
