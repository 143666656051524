import {
  Component,
  TemplateRef,
  ViewChild,
  Input,
  OnInit,
  ContentChild,
  inject,
  AfterContentInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { IconCloseComponent, IconSize } from '@pedix-workspace/angular-ui-icons';
import { ModalHistoryComponent } from '../modal-history/modal-history.component';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { IconComponent } from '@pedix-workspace/angular-ui-icons';
import { NgTemplateOutlet } from '@angular/common';

export type ModalDialogType = 'confirm' | 'alert' | 'modal';

@Component({
  selector: 'pxw-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, ButtonComponent, ModalHistoryComponent, IconCloseComponent],
})
export class ModalDialogComponent implements OnInit, AfterContentInit {
  @ContentChild(IconComponent) icon: IconComponent;

  @Input() iconSize: IconSize = 'md';
  @Input() title: string | undefined;
  @Input() type: ModalDialogType = 'alert';
  @Input() acceptText = '';
  @Input() cancelText = '';
  @Input() confirmText = '';
  @Input() showCloseButton = false;
  @Input() confirmDisabled = false;
  @Input() modalClasses: string;
  @Input() size: 'sm' | 'xs' = 'sm';

  @Output() isOpenChange = new EventEmitter<boolean>();

  @ViewChild('modalContentWrapper') private modalContentWrapper!: TemplateRef<HTMLElement>;
  @ContentChild('modalContent') modalContent: TemplateRef<HTMLElement>;
  @ContentChild('modalButtons') modalButtons: TemplateRef<{ close: () => {} }>;

  isOpen = false;
  loading = false;
  modalRef: NgbModalRef | undefined;

  confirmAction: () => void | unknown;
  confirmResolve?: (returnValue: unknown | PromiseLike<unknown>) => void;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private modalService = inject(NgbModal);
  private t = inject(TranslocoService);

  ngOnInit() {
    if (!this.acceptText) {
      this.acceptText = this.t.translate('components.modalDialogAcceptText');
    }
    if (!this.cancelText) {
      this.cancelText = this.t.translate('components.modalDialogCancelText');
    }
    if (!this.confirmText) {
      this.confirmText = this.t.translate('components.modalDialogConfirmText');
    }
  }

  ngAfterContentInit(): void {
    if (this.icon) {
      this.icon.size = this.iconSize;
      this.icon.color = 'pink';
    }
  }

  open<T>(confirmAction?: () => Promise<T>): Promise<T> {
    this.toggleIsOpen(true);

    this.modalRef = this.modalService.open(this.modalContentWrapper, {
      windowClass: `animated bounceInUp modal-dialog-component dialog-size-${this.size} dialog-type-${this.type} ${this.modalClasses}`,
      size: 'lg',
      backdrop: 'static',
    });

    if (typeof confirmAction === 'function') {
      this.confirmAction = confirmAction;

      const confirmPromise = new Promise<unknown>(resolve => {
        this.confirmResolve = resolve;
      });

      return this.modalRef.result.then(() => confirmPromise as Promise<T>);
    } else {
      this.confirmResolve = undefined;
    }

    return this.modalRef.result.then(value => {
      return new Promise(resolve => {
        // NOTE: this timeout was added to prevent conflict when poping state with modal-history and router navigation on promise resolve
        setTimeout(() => {
          return resolve(value);
        }, 500);
      });
    });
  }

  async onConfirmAction() {
    this.loading = true;

    try {
      if (typeof this.confirmAction === 'function') {
        const result = await this.confirmAction();

        if (this.confirmResolve) {
          // NOTE: this timeout was added to prevent conflict when poping state with modal-history and router navigation on promise resolve
          setTimeout(() => {
            this.confirmResolve?.(result);
          }, 500);
        }
      }

      this.close();
    } catch (error) {
      console.error(error);
    }

    this.loading = false;
  }

  dismiss(reason: string) {
    if (!this.isOpen) {
      return;
    }
    this.modalRef?.dismiss(reason);
    this.toggleIsOpen(false);
  }

  close = () => {
    if (!this.isOpen) {
      return;
    }
    this.modalRef?.close();
    this.toggleIsOpen(false);
  };

  private toggleIsOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    this.isOpenChange.emit(isOpen);
  }
}
