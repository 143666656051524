import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { currencyFormat, CurrencyFormatOptions } from '@pedix-workspace/utils';
import { CurrencyFormatService } from './currency-format.service';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'currencyFormat',
  standalone: true,
})
export class CurrencyFormatPipe implements PipeTransform {
  private currencyFormatService = inject(CurrencyFormatService);

  transform(value = 0, formatOptions: CurrencyFormatOptions = {}): string {
    return currencyFormat(value, this.currencyFormatService.currencyLocaleData, formatOptions);
  }
}
