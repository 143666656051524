<ng-container *transloco="let t">
  <ul class="total-details-list">
    <li class="total-details-list-item">
      <span class="item-title">{{ t('totalDetails.subtotalLabel') }}:</span>
      <span class="item-value">{{
        orderDetails.totalAmount | currencyFormat: { forceDecimals: true }
      }}</span>
    </li>
    @if (orderDetails.totalProductDiscount) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.totalProductDiscountLabel') }}:</span>
        <span class="item-value"
          >- {{ orderDetails.totalProductDiscount | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    @if (orderDetails.couponDiscount) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.couponDiscountLabel') }}:</span>
        <span class="item-value"
          >- {{ orderDetails.couponDiscount | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    @if (orderDetails.deliveryCost) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.deliveryCostLabel') }}:</span>
        <span class="item-value"
          >+ {{ orderDetails.deliveryCost | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    @if (orderDetails.outOfDeliveryZone) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.deliveryCostLabel') }}:</span>
        <span class="item-value">{{ t('totalDetails.outOfDeliveryZoneValue') }}</span>
      </li>
    }
    @if (orderDetails.paymentMethodExtraCharge) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.extraChargeLabel') }}:</span>
        <span class="item-value"
          >+
          {{
            orderDetails.paymentMethodExtraCharge | currencyFormat: { forceDecimals: true }
          }}</span
        >
      </li>
    }
    @if (orderDetails.paymentMethodDiscount) {
      <li class="total-details-list-item">
        <span class="item-title">{{ t('totalDetails.discountLabel') }}:</span>
        <span class="item-value"
          >-
          {{ orderDetails.paymentMethodDiscount | currencyFormat: { forceDecimals: true } }}</span
        >
      </li>
    }
    <!-- Food Bank Donation -->
    @if (orderDetails.foodBankDonationAmount) {
      <li class="total-details-list-item">
        <span class="item-title">Donación Banco de Alimentos:</span>
        <span class="item-value">
          + {{ orderDetails.foodBankDonationAmount | currencyFormat: { forceDecimals: true } }}
        </span>
      </li>
    }
    <li class="total-details-list-item total">
      <span class="item-title">{{ t('totalDetails.finalAmount') }}:</span>
      <span class="item-value">{{
        orderDetails.finalAmount | currencyFormat: { forceDecimals: true }
      }}</span>
    </li>
  </ul>
</ng-container>
