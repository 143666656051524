import { MessageRenderParams, MessageRenderType } from '../order-message.types';
import {
  OrderMessageRendererBase,
  OrderMessageRendererKeyValueParams,
  OrderMessageRendererNestedFn,
  OrderMessageRendererTextModifiers,
} from './order-message-renderer-base.class';

const INDENT_BY_LEVEL = {
  0: '',
  1: '&nbsp;&nbsp;',
  2: '&nbsp;&nbsp;&nbsp;&nbsp;',
};

export class OrderMessageRendererHtml extends OrderMessageRendererBase {
  private buffer: string[];

  constructor(protected renderParams: MessageRenderParams) {
    super(renderParams);

    this.buffer = [];
  }

  getType(): MessageRenderType {
    return 'html';
  }
  getIdentationByLevel(identationLevel: number): string {
    return INDENT_BY_LEVEL[identationLevel];
  }
  getCurrencyOptions() {
    return {
      forceDecimals: true,
      separateSymbol: false,
    };
  }
  getSystemMessageIcon(): string {
    return '&#9654;';
  }

  renderLineBreak() {
    this.buffer.push('\n');

    return this;
  }
  renderEmptyLine() {
    this.buffer.push('<br />\n');

    return this;
  }
  renderLine(
    param1: string | OrderMessageRendererNestedFn,
    modifiers?: OrderMessageRendererTextModifiers,
  ) {
    this.renderText('<p>');
    if (typeof param1 === 'function') {
      param1();
    } else {
      this.renderText(param1, modifiers);
    }
    this.renderText('</p>');
    this.renderLineBreak();

    return this;
  }
  renderText(text: string, modifiers?: OrderMessageRendererTextModifiers) {
    const textParts = [];

    if (modifiers?.alignment !== undefined) {
      textParts.push(`<span style="display: block; text-align: ${modifiers.alignment}">`);
    }
    if (modifiers?.bold !== undefined) {
      textParts.push('<b>');
    }
    if (modifiers?.italic !== undefined) {
      textParts.push('<i>');
    }
    if (modifiers?.monospace !== undefined) {
      textParts.push('<samp>');
    }
    if (modifiers?.indentLevel !== undefined) {
      textParts.push(this.getIdentationByLevel(modifiers.indentLevel));
    }

    textParts.push(modifiers?.uppercase ? text.toUpperCase() : text);

    if (modifiers?.monospace !== undefined) {
      textParts.push('</samp>');
    }
    if (modifiers?.italic !== undefined) {
      textParts.push('</i>');
    }
    if (modifiers?.bold !== undefined) {
      textParts.push('</b>');
    }
    if (modifiers?.alignment !== undefined) {
      textParts.push(`</span>`);
    }

    this.buffer.push(textParts.join(''));

    return this;
  }
  renderKeyValueLine({
    keyText,
    keyModifiers,
    valueText,
    valueModifiers,
  }: OrderMessageRendererKeyValueParams) {
    this.renderText('<p>');
    this.renderText(`${keyText}:`, keyModifiers);
    this.renderText(' ');
    this.renderText(valueText, valueModifiers);
    this.renderText('</p>');
    this.renderLineBreak();

    return this;
  }
  renderSectionTitle(text: string) {
    this.renderLine(text, { bold: true, uppercase: true });

    return this;
  }
  renderObservations(
    observations: string,
    modifiers?: Pick<OrderMessageRendererTextModifiers, 'indentLevel'>,
  ): OrderMessageRendererBase {
    this.renderText('<p>');
    this.renderText('*: ', { indentLevel: modifiers?.indentLevel });
    this.renderText(`"${observations}"`, { italic: true });
    this.renderText('</p>');
    this.renderLineBreak();

    return this;
  }
  renderSystemMessage(
    message: string,
    modifiers?: Pick<OrderMessageRendererTextModifiers, 'indentLevel'>,
  ): OrderMessageRendererBase {
    this.renderText('<p>');
    this.renderText(`${this.getSystemMessageIcon()} `, { indentLevel: modifiers?.indentLevel });
    this.renderText(message, { italic: true });
    this.renderText('</p>');
    this.renderLineBreak();

    return this;
  }
  renderColumns(
    columnValues: string[],
    columnSizes?: number[],
    modifiers?: OrderMessageRendererTextModifiers,
  ): OrderMessageRendererBase {
    if (!columnSizes) {
      columnSizes = columnValues.map(value => value.length);
    }
    const columnFractions = columnSizes.map(value => `${value}fr`).join(' ');

    this.renderText(`<div style="display: grid; grid-template-columns: ${columnFractions}">`);

    columnValues.forEach((value, i) => {
      const isLast = i === columnValues.length - 1;

      // this.renderText(`<p style="justify-self: flex-end"></p>`)
      this.renderLine(value, { ...(modifiers || {}), alignment: isLast ? 'right' : 'left' });
    });

    this.renderText(`</div>`);
    this.renderLineBreak();

    return this;
  }
  renderSeparator(): OrderMessageRendererBase {
    this.renderLine('<hr />');

    return this;
  }
  output() {
    return this.buffer.join('');
  }
}
