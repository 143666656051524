import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class EnvironmentService {
  
  env: 'production' | 'development';
  siteUrl: string;
  isAdminApp: boolean;
  gmapsApiKey: string;
  
  get shouldWatchChanges(): boolean {
    return this.isAdminApp;
  }
}