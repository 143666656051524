<ng-container *transloco="let t">
  @if (layout === 'presentation-item') {
    <span class="current-price">
      <span class="price-from">{{ t('productList.priceFrom') }}</span>
      {{ price | currencyFormat }}
    </span>
    @if (maxDiscount) {
      <span class="discount-percentage">
        <pxw-tag type="success" size="xs">
          {{ t('product.maxDiscount', { maxDiscount }) }}
        </pxw-tag>
      </span>
    }
  } @else if (priceDiscount && priceDiscount !== price) {
    <span class="previous-price">{{ price | currencyFormat }}</span>
    <span class="current-price">{{ priceDiscount | currencyFormat }}</span>

    @if (displayPercentage) {
      <span class="discount-percentage">
        <pxw-tag type="success" size="xs">
          {{
            t('product.priceDiscountPercentage', {
              discountPercentage: price | pxwPriceDiscount: priceDiscount
            })
          }}
        </pxw-tag>
      </span>
    }
  } @else {
    <span class="current-price">{{ price | currencyFormat }}</span>
  }
</ng-container>
