import { Component, Input, inject } from '@angular/core';
import { WorkingDays, TimeRange, DAY_OF_WEEK_NAMES } from '@pedix-workspace/utils';

import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';


@Component({
  selector: 'pxw-working-hours-message-details',
  templateUrl: './working-hours-message-details.component.html',
  styleUrls: ['./working-hours-message-details.component.scss'],
  standalone: true,
  imports: [TranslocoDirective],
})
export class WorkingHoursMessageDetailsComponent {
  @Input() workingDays: WorkingDays;

  DAY_OF_WEEK_NAMES: any = DAY_OF_WEEK_NAMES;

  private t = inject(TranslocoService);
  
  constructor() {}

  getDaysOfWeekMessage() {
    return (
      this.workingDays.selectedDaysOfWeek
        /**
         * t(dow.1, dow.2, dow.3, dow.4, dow.5, dow.6, dow.7)
         */
        .map(dayOfWeek => this.t.translate(`dow.${dayOfWeek}`))
        .reduce(
          (text, value, i, array) =>
            text + (i < array.length - 1 ? ', ' : ` ${this.t.translate(`global.and`)} `) + value,
        )
    );
  }

  getWorkingHoursMessage(primaryWorkingHours?: TimeRange, secondaryWorkingHours?: TimeRange) {
    let message = '';

    if (primaryWorkingHours && primaryWorkingHours.from && primaryWorkingHours.to) {
      message += this.t.translate('workingHours.fromToText', {
        timeFrom: this.formatHour(primaryWorkingHours.from),
        timeTo: this.formatHour(primaryWorkingHours.to),
      });
    }

    if (secondaryWorkingHours && secondaryWorkingHours.from && secondaryWorkingHours.to) {
      message +=
        ' ' +
        this.t.translate('workingHours.andFromToText', {
          timeFrom: this.formatHour(secondaryWorkingHours.from),
          timeTo: this.formatHour(secondaryWorkingHours.to),
        });
    }
    return message;
  }

  private formatHour(militaryHour: number) {
    if (militaryHour > 2400) {
      militaryHour -= 2400;
    }
    const amPm = militaryHour < 1200 ? 'AM' : 'PM';
    const hourString = String(militaryHour).padStart(4, '0');

    return `${hourString.substr(0, 2)}:${hourString.substr(2, 2)}${amPm}`;
  }
}
