import { Injectable } from '@angular/core';
import { CurrencyConfig } from './input-number/input-number.component';

@Injectable({ providedIn: 'root' })
export class UiFormReactiveConfigService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage(key: string, data: any, options?: { isArray?: boolean }) {
    switch (key) {
      case 'required':
        return this.getErrorRequiredMessage();
      case 'minlength':
        if (options?.isArray) {
          return this.getErrorMinMessage(data.requiredLength);
        } else {
          return this.getErrorMinLengthMessage(data.requiredLength);
        }
      case 'maxlength':
        if (options?.isArray) {
          return this.getErrorMaxMessage(data.requiredLength);
        } else {
          return this.getErrorMaxLengthMessage(data.requiredLength);
        }
      case 'pattern':
        return this.getErrorPatternMessage();
      case 'email':
        return this.getErrorEmailMessage();
      case 'min':
        return this.getErrorMinMessage(data.min);
      case 'max':
        return this.getErrorMaxMessage(data.max);
      case 'custom':
        return this.getErrorCustomMessage(data);
      default:
        return `Error: ${key}`;
    }
  }

  getErrorRequiredMessage(): string {
    return 'Required';
  }

  getErrorMinLengthMessage(minLength: number): string {
    return `Minimum characters: ${minLength}`;
  }

  getErrorMaxLengthMessage(minLength: number): string {
    return `Maximum characters: ${minLength}`;
  }

  getErrorPatternMessage(): string {
    return `Invalid input`;
  }

  getErrorEmailMessage(): string {
    return `Invalid email`;
  }

  getErrorMinMessage(min: number): string {
    return `Minimum: ${min}`;
  }

  getErrorMaxMessage(max: number): string {
    return `Maximum: ${max}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getErrorCustomMessage(data: unknown): string {
    return `Custom validation`;
  }

  getMonths(): string[] {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  getWeekDaysStartingOnSunday(): string[] {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  }

  getCurrencyConfig(): CurrencyConfig {
    return {
      symbol: '$',
      decimals: 2,
      groupSeparator: '',
      decimalSeparator: '.',
    };
  }
}
