import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ContentChild,
  ViewChild,
  ElementRef,
} from '@angular/core';
import clsx from 'clsx';
import { CardFooterComponent } from '../card/card-footer.component';
import { CardHeaderComponent } from './card-header.component';
import { NgClass } from '@angular/common';

export const cardThemes = ['light', 'dark'] as const;
export const cardSizes = ['sm', 'lg'] as const;

export type CardThemes = typeof cardThemes;
export type CardTheme = CardThemes[number];

export type CardSizes = typeof cardSizes;
export type CardSize = CardSizes[number];

@Component({
  selector: 'pxw-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class CardComponent {
  @ContentChild(CardHeaderComponent) cardHeader: CardHeaderComponent;
  @ContentChild(CardFooterComponent) cardFooter: CardFooterComponent;

  @ViewChild('cardContent') cardContent: ElementRef<HTMLElement>;

  @Input() theme: CardTheme = 'light';
  @Input() size: CardSize = 'lg';
  @Input() rounded = true;
  @Input() contentScrollEnabled = true;
  @Input() contentPaddingEnabled = true;

  @HostBinding('class')
  get classes() {
    return clsx(`theme-${this.theme}`, `size-${this.size}`, {
      'with-header': Boolean(this.cardHeader),
      'with-footer': Boolean(this.cardFooter),
      rounded: this.rounded,
    });
  }
}
