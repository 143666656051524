import { Component, Input } from '@angular/core';
import { EndOrderDetails } from '@pedix-workspace/utils';


import { TranslocoDirective } from '@ngneat/transloco';
import { CurrencyFormatPipe } from '@pedix-workspace/pedixapp-shared-pipes';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-order-total-details',
  templateUrl: './total-details.component.html',
  styleUrls: ['./total-details.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, CurrencyFormatPipe],
})
export class OrderTotalDetailsComponent {
  @Input() orderDetails: EndOrderDetails;
}
