import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';
import { CartItem } from '@pedix-workspace/utils';
import { endOrderToOrderItemsByCategories, OrderItemsByCategories } from '@pedix-workspace/orders';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'pxw-cart-items-list',
  templateUrl: './cart-items-list.component.html',
  styleUrls: ['./cart-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class CartItemsListComponent {
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<{ $implicit: CartItem; index: number }>;

  protected orderItems: OrderItemsByCategories;

  @Input() set cartItems(cartItems: CartItem[]) {
    this.orderItems = endOrderToOrderItemsByCategories(cartItems);
  }
  @Input() undefinedGroupName = 'string';
}
