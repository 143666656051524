import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="note"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconNoteComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37 5H9C6.794 5 5 6.794 5 9V37C5 39.206 6.794 41 9 41H25C25.2627 41.0004 25.5229 40.9488 25.7655 40.8482C26.0082 40.7477 26.2286 40.6001 26.414 40.414L40.414 26.414C40.5784 26.2435 40.7112 26.0451 40.806 25.828C40.834 25.768 40.85 25.706 40.872 25.642C40.9289 25.4747 40.9632 25.3004 40.974 25.124C40.978 25.082 41 25.042 41 25V9C41 6.794 39.206 5 37 5ZM9 9H37V23H25C24.4696 23 23.9609 23.2107 23.5858 23.5858C23.2107 23.9609 23 24.4696 23 25V37H9V9ZM27 34.172V27H34.172L27 34.172Z"
    />
  </svg>`,
})
export class IconNoteComponent extends IconComponent {}
