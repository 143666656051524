@if (label) {
  <label
    class="ui-label ui-label--{{ labelSize }}"
    [ngClass]="{ 'ui-label--required': isRequired }"
    [attr.for]="inputId"
  >
    {{ label }}
  </label>
}
<div class="ui-input__container ui-input__container--with-addons">
  <div class="ui-input__addon-wrapper">
    <ng-content select=".addon-prepend"></ng-content>
  </div>

  <div class="ui-input__editor">
    <input
      #input
      [id]="inputId"
      [value]="value"
      class="ui-input__input"
      [ngClass]="{ 'ui-input__input--without-spinners': !displaySpinners }"
      [type]="inputType"
      [autofocus]="autofocus"
      pattern="[0-9,\.\-\+]"
      [placeholder]="placeholder"
      [readonly]="isDisabled"
      [inputMask]="currencyMask"
      (keyup)="onKeyUp($event)"
      (change)="onInputChange($event)"
      (focusout)="onFocusOut($event)"
      (paste)="onPaste($event)"
    />
    @if (shouldDisplayErrors) {
      <pxw-icon class="ui-input__error-icon" icon="info" size="sm" />
    }
  </div>

  <div class="ui-input__addon-wrapper">
    <ng-content select=".addon-append"></ng-content>
  </div>
</div>
@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
  </div>
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<ng-content></ng-content>
