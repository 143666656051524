import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { EnvironmentService } from '@pedix-workspace/angular-utils';
import { EstablishmentUrlData, appEstablishmentUrl } from '@pedix-workspace/utils';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'establishmentUrl',
  standalone: true,
})
export class EstablishmentUrlPipe implements PipeTransform {
  private environmentService = inject(EnvironmentService);
  
  constructor() {}

  transform(establishment: EstablishmentUrlData, pathParts?: string[]): string {
    return appEstablishmentUrl(this.environmentService.siteUrl, establishment, pathParts);
  }
}
