import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="back"],pxw-icon[icon="arrow-up"],pxw-icon[icon="arrow-down"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconBackComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.207 20.8587H11.4949L19.3646 11.5167C19.7326 11.0791 19.9097 10.515 19.8568 9.94839C19.804 9.38181 19.5255 8.85919 19.0828 8.4955C18.6401 8.1318 18.0693 7.95683 17.496 8.00907C16.9228 8.0613 16.394 8.33647 16.026 8.77404L5.18613 21.63C5.1132 21.7323 5.04799 21.8397 4.99102 21.9514C4.99102 22.0586 4.99102 22.1228 4.83926 22.23C4.74099 22.4757 4.68954 22.7372 4.6875 23.0013C4.68954 23.2655 4.74099 23.527 4.83926 23.7727C4.83926 23.8798 4.83926 23.9441 4.99102 24.0512C5.04799 24.163 5.1132 24.2704 5.18613 24.3726L16.026 37.2286C16.2298 37.4705 16.4851 37.665 16.7736 37.7983C17.0621 37.9317 17.3768 38.0005 17.6953 38C18.2019 38.001 18.6928 37.8266 19.0828 37.5072C19.3023 37.3273 19.4838 37.1064 19.6168 36.8571C19.7498 36.6078 19.8318 36.335 19.8579 36.0544C19.8841 35.7738 19.854 35.4908 19.7694 35.2216C19.6847 34.9525 19.5472 34.7025 19.3646 34.486L11.4949 25.144H37.207C37.782 25.144 38.3334 24.9183 38.74 24.5164C39.1466 24.1146 39.375 23.5696 39.375 23.0013C39.375 22.4331 39.1466 21.8881 38.74 21.4862C38.3334 21.0844 37.782 20.8587 37.207 20.8587Z"
      />
    </svg>
  `,
})
export class IconBackComponent extends IconComponent {}
