export const BUCKET_SUFFIX = 'appspot.com/';
export const IMAGE_CDN_URL = 'https://cdn.pedix.app';

export type CdnImageOptions = {
  size?: string;
  quality?: number;
};

export const getImageCdnUrl = (
  imageUrl: string,
  options: Partial<CdnImageOptions> = {},
  env?: 'development' | 'production',
): string => {
  if (!imageUrl) {
    return '';
  }
  if (imageUrl.startsWith(IMAGE_CDN_URL)) {
    return imageUrl;
  }

  const baseImageUrl = imageUrl.split('?')[0];
  const imagePath = baseImageUrl.substring(
    baseImageUrl.indexOf(BUCKET_SUFFIX) + BUCKET_SUFFIX.length,
  );

  const parsedImagePath = baseImageUrl.startsWith('https://firebasestorage.googleapis.com')
    ? decodeURIComponent(imagePath.replace(/^o\//, ''))
    : imagePath;

  const searchParams = new URLSearchParams();

  if (options.size) {
    searchParams.append('size', options.size);
  }
  if (options.quality) {
    searchParams.append('quality', options.quality.toString());
  }
  if (env === 'development') {
    searchParams.append('env', env);
  }

  return `${IMAGE_CDN_URL}/${parsedImagePath}?${searchParams.toString()}`;
};
