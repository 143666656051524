import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, InjectionToken, inject } from '@angular/core';
import {
  HAutocompleteParams,
  HAutocompleteResponse,
  HLookupParams,
  HLookupResponse,
  HReverseGeocodingParams,
  HReverseGeocodingResponse,
} from './here-maps.types';
import { firstValueFrom, take } from 'rxjs';

export const HERE_MAPS_SERVICE_API_KEY = new InjectionToken<string>('HERE_MAPS_SERVICE_API_KEY');

@Injectable({ providedIn: 'root' })
export class HereMapsService {
  private httpClient = inject(HttpClient);
  private apiKey = inject(HERE_MAPS_SERVICE_API_KEY);

  async autocomplete(params: HAutocompleteParams) {
    const apiUrl = `https://autocomplete.search.hereapi.com/v1/autocomplete`;

    const request$ = this.httpClient
      .get<HAutocompleteResponse>(apiUrl, {
        params: new HttpParams().appendAll({
          apiKey: this.apiKey,
          politicalView: 'ARG',
          ...params,
        }),
      })
      .pipe(take(1));

    return firstValueFrom(request$);
  }

  async autosuggest(params: HAutocompleteParams) {
    const apiUrl = `https://autosuggest.search.hereapi.com/v1/autosuggest`;

    const request$ = this.httpClient
      .get<HAutocompleteResponse>(apiUrl, {
        params: new HttpParams().appendAll({
          apiKey: this.apiKey,
          politicalView: 'ARG',
          ...params,
        }),
      })
      .pipe(take(1));

    return firstValueFrom(request$);
  }

  async lookup(params: HLookupParams) {
    const apiUrl = `https://lookup.search.hereapi.com/v1/lookup`;
    let httpParams = new HttpParams().appendAll({
      apiKey: this.apiKey,
      politicalView: 'ARG',
      id: params.id,
    });
    if (params.show) {
      httpParams = httpParams.append('show', params.show.join(','));
    }
    const request$ = this.httpClient
      .get<HLookupResponse>(apiUrl, {
        params: httpParams,
      })
      .pipe(take(1));

    return firstValueFrom(request$);
  }

  async reverseGeocode(params: HReverseGeocodingParams) {
    const apiUrl = `https://revgeocode.search.hereapi.com/v1/revgeocode`;
    let httpParams = new HttpParams().appendAll({
      apiKey: this.apiKey,
      politicalView: 'ARG',
      at: params.at,
    });
    if (params.types) {
      httpParams = httpParams.append('types', params.types.join(','));
    }
    if (params.show) {
      httpParams = httpParams.append('show', params.show.join(','));
    }

    const request$ = this.httpClient
      .get<HReverseGeocodingResponse>(apiUrl, {
        params: httpParams,
      })
      .pipe(take(1));

    return firstValueFrom(request$);
  }
}
