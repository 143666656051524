import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CartItem, PricingError } from '@pedix-workspace/utils';
import { AnalyticsService, SessionStorageService } from '@pedix-workspace/pedixapp-core-services';
import { consolidateCartItems } from '@pedix-workspace/orders';
import { CheckoutPreloaderService } from './features/order-checkout/checkout-preloader.service';
import { AppRequestContextService } from './app-request-context.service';
import { cloneDeep } from 'lodash-es';

const STORAGE_NAMESPACE = 'cart-state';

@Injectable({ providedIn: 'root' })
export class AppCartItemsService {
  checkoutPreloaderService = inject(CheckoutPreloaderService);
  appRequestContextService = inject(AppRequestContextService);

  private establishmentSlug: string;
  private cartItemsBehavior = new BehaviorSubject<CartItem[]>([]);

  cartItemsObservable$ = this.cartItemsBehavior.asObservable();

  get cartItems() {
    return this.cartItemsBehavior.value;
  }

  get displaySku(): boolean {
    return this.appRequestContextService.establishment.catalogConfiguration?.displaySku || false;
  }

  private sessionStorage = inject(SessionStorageService);
  private analyticsService = inject(AnalyticsService);

  initSession(establishmentSlug: string) {
    this.establishmentSlug = establishmentSlug;

    const cartItems = this.restoreSession(this.establishmentSlug);

    this.cartItemsBehavior = new BehaviorSubject(cartItems);
    this.cartItemsObservable$ = this.cartItemsBehavior.asObservable();
  }

  clearProducts() {
    this.storeSession(this.establishmentSlug, []);

    this.cartItemsBehavior.next([]);
  }

  addCartItem(cartItem: CartItem) {
    const cartItems = consolidateCartItems(
      [...this.cartItemsBehavior.value, cartItem],
      this.displaySku,
    );

    this.cartItemsBehavior.next(cartItems);

    this.storeSession(this.establishmentSlug, cartItems);

    this.analyticsService.addCartItem(cartItem);

    // Preloads checkout dependencies for better UX
    this.checkoutPreloaderService.preloadCheckout();
  }

  updateCartItem(cartItem: CartItem): CartItem[] {
    const cartItemIndex = this.cartItemsBehavior.value.findIndex(
      currentCartItem => currentCartItem.id === cartItem.id,
    );
    const oldCartItem = this.cartItemsBehavior.value[cartItemIndex];
    const cartItems = consolidateCartItems(
      Object.assign([], this.cartItemsBehavior.value, { [cartItemIndex]: cartItem }),
      this.displaySku,
    );

    this.cartItemsBehavior.next(cartItems);

    this.storeSession(this.establishmentSlug, cartItems);

    this.analyticsService.updateCartItem(oldCartItem, cartItem);

    return cartItems;
  }

  removeCartItem(cartItem: CartItem) {
    const cartItems = this.cartItemsBehavior.value.filter(
      currentCartItem => currentCartItem.id !== cartItem.id,
    );

    this.cartItemsBehavior.next(cartItems);

    this.storeSession(this.establishmentSlug, cartItems);

    this.analyticsService.removeCartItem(cartItem);
  }

  findCartItem(cartItemId: string): CartItem | undefined {
    return this.cartItemsBehavior.value.find(cartItem => cartItem.id === cartItemId);
  }

  updateItemPrices(pricingErrors: PricingError[]) {
    const cartItemsCopy = cloneDeep(this.cartItems);

    pricingErrors.forEach(pricingError => {
      switch (pricingError.type) {
        case 'product':
          cartItemsCopy.forEach(cartItem => {
            if (cartItem.product.id === pricingError.id) {
              cartItem.product.price = pricingError.newPrice;
              cartItem.product.priceDiscount = pricingError.newPriceDiscount;
            }
          });
          break;
        case 'presentation':
          cartItemsCopy.forEach(cartItem => {
            cartItem.selectedPresentation?.items.forEach(presentationItem => {
              if (presentationItem.id === pricingError.id) {
                presentationItem.price = pricingError.newPrice;
                presentationItem.priceDiscount = pricingError.newPriceDiscount;
              }
            });
          });
          break;
        case 'option-item':
          cartItemsCopy.forEach(cartItem => {
            cartItem.selectedOptions?.forEach(selectedOption => {
              selectedOption.items?.forEach(optionItem => {
                if (optionItem.id === pricingError.id) {
                  optionItem.price = pricingError.newPrice;
                  optionItem.priceDiscount = pricingError.newPriceDiscount;
                }
              });
            });
          });
          break;
      }
    });

    this.cartItemsBehavior.next(cartItemsCopy);

    this.storeSession(this.establishmentSlug, cartItemsCopy);
  }

  private restoreSession(establishmentSlug: string): CartItem[] {
    if (this.sessionStorage.hasItem(establishmentSlug, STORAGE_NAMESPACE)) {
      const cartItems: CartItem[] = this.sessionStorage.getItem(
        establishmentSlug,
        STORAGE_NAMESPACE,
      );

      return cartItems;
    }
    return [];
  }

  private storeSession(establishmentSlug: string, cartItems: CartItem[]) {
    this.sessionStorage.setItem(establishmentSlug, cartItems, STORAGE_NAMESPACE);
  }
}
