import { Injectable, inject } from '@angular/core';
import { Storage, ref, uploadBytes, getDownloadURL, deleteObject } from '@angular/fire/storage';
import { base64ToFile } from 'ngx-image-cropper';
import { NGXLogger } from 'ngx-logger';
import { getFileExtensionFromMimeType } from '@pedix-workspace/utils';
import qup from 'qup';

export type FileUploadOptions = {
  temp?: boolean;
};
export type UploadFileRef = {
  filePath: string;
  file: Blob;
};

@Injectable({
  providedIn: 'root',
})
export class FileStorageService {
  private storage = inject(Storage);
  private logger = inject(NGXLogger);

  constructor() {}

  async uploadBase64File(
    filePath: string,
    base64File: string,
    options: FileUploadOptions = {},
  ): Promise<string> {
    const file = base64ToFile(base64File);

    return this.uploadFile(filePath, file, options);
  }

  async uploadFile(filePath: string, file: Blob, options: FileUploadOptions = {}): Promise<string> {
    const fileExtension = getFileExtensionFromMimeType(file.type);
    const fileUrl = `${options.temp ? 'temp-uploads/' : ''}${filePath}.${fileExtension}`;
    const fileRef = ref(this.storage, fileUrl);

    await uploadBytes(fileRef, file, {
      contentType: file.type,
      cacheControl: 'public, max-age: 31536000, immutable',
    }).then();

    return getDownloadURL(fileRef);
  }

  deleteFile(filePath: string) {
    const fileRef = ref(this.storage, filePath);

    return deleteObject(fileRef).catch(error => {
      this.logger.error('file-storage.service::deleteFile', error);
    });
  }

  async batchUploadFiles(
    filesToUpload: UploadFileRef[],
    options: FileUploadOptions = {},
    concurrency = 10,
  ): Promise<(string | Error)[]> {
    const results: (string | Error)[] = [];

    const q = qup(async ([fileToUpload, index]: [UploadFileRef, number]) => {
      try {
        const uri = await this.uploadFile(fileToUpload.filePath, fileToUpload.file, options);

        results[index] = uri;
      } catch (error) {
        results[index] = <Error>error;
      }
    }, concurrency);

    filesToUpload.forEach((fileToUpload, index) => q.push([fileToUpload, index]));

    await q.drain();

    return results;
  }

  async batchDeleteFiles(filesToDelete: string[], concurrency = 10): Promise<void> {
    const errors: Error[] = [];

    const q = qup(async (fileToDelete: string) => {
      try {
        await this.deleteFile(fileToDelete);
      } catch (error) {
        errors.push(<Error>error);
      }
    }, concurrency);

    if (errors.length > 0) {
      console.error(errors);
    }

    filesToDelete.forEach(fileToDelete => q.push(fileToDelete));

    await q.drain();
  }
}
