<ng-container *transloco="let t">
  @for (cartItemDetailsItem of cartItemDetailsItems; track cartItemDetailsItem) {
    <div class="detail">
      @if (cartItemDetailsItem.optionName) {
        <strong class="option-name">- {{ cartItemDetailsItem.optionName }}: </strong>
      }
      @for (item of cartItemDetailsItem.items; track item; let i = $index) {
        <span class="item">
          @if (item.sku) {
            <strong class="item-sku">{{ item.sku }}</strong>
          }
          <span class="item-name">{{ item.name }}</span>
          @if (cartItemDetailsItem.items.length > 1 || item.quantity > 1) {
            <span class="item-qty"> (x{{item.quantity}})</span>
          }
        </span>
      }
    </div>
  }
</ng-container>
