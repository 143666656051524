import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  OnInit,
  inject,
  PLATFORM_ID,
  OnChanges,
  SimpleChanges,
  signal,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

declare global {
  interface Window {
    lazySizes: unknown;
  }
}

import { IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { NgClass, NgStyle, AsyncPipe, isPlatformBrowser } from '@angular/common';

const errorImage = 'https://cdn.pedix.app/no-product-image.png?alt=media&size=600';

/**
 * IMPORTANT: this component uses "lazySizes" library under the hood to ensure lazy-loading behavior
 * - It doesn't require any initialization; adding "lazyload" class to image is enough
 * - We once tried adding the npm import here, but it affected other 3rd party library such as ngx-colors, so it was decided to load it externally with an script tag in any requiring project
 * - lazySizes script URL: https://cdn.jsdelivr.net/npm/lazysizes@5.3.2/lazysizes.min.js
 */
@UntilDestroy()
@Component({
  selector: 'pxw-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, IconLoadingComponent, NgStyle, AsyncPipe],
})
export class ImageComponent implements OnInit, OnChanges {
  private platformId = inject(PLATFORM_ID);

  @Input({ required: true }) src: string | null;
  @Input() objectFit: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none' = 'contain';
  @Input() aspectRatio: number | null = null;
  @Input() width: number | null = null;
  @Input() height: number | null = null;
  @Input() alt = '';
  @Input() loader: 'sm' | 'lg' | 'none' = 'lg';
  @Input() lazyLoad = false;

  #loading = signal<boolean>(this.lazyLoad ? true : false);
  #hasError = signal<boolean>(false);

  @HostBinding('style.aspect-ratio') get aspectRatioStyle() {
    return this.aspectRatio;
  }

  @HostBinding('style.width.px') get widthStyle() {
    return this.width;
  }

  @HostBinding('style.height.px') get heightStyle() {
    return this.height;
  }

  get imageUrl() {
    return this.#hasError() ? errorImage : this.src;
  }

  get isLoading() {
    return this.#loading();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && typeof window.lazySizes === 'undefined') {
      console.warn(
        'Lazysizes library is not loaded; add https://cdn.jsdelivr.net/npm/lazysizes@5.3.2/lazysizes.min.js to index.html file',
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src']?.currentValue !== changes['src']?.previousValue) {
      this.#loading.set(true);
      this.#hasError.set(false);
    }
  }

  onImageLoad() {
    this.#loading.set(false);
  }

  onImageError() {
    this.#loading.set(true);
    this.#hasError.set(true);
  }
}
