import { Injectable, PLATFORM_ID, Renderer2, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  CartItem,
  Category,
  EndOrderDetails,
  Product,
  PlanType,
  AdminPanelOptionType,
  BillingType,
  PaymentGateway,
  PaymentMethodPriceModifier,
  AdminPanelLinksNavigation,
  AdminPanelHeaderNavigation,
  REPORT_TYPE,
  BulkActionType,
  OrderCancelReason,
  DateRangeKey,
  StatisticsTypes,
  StatisticsAdvancedTypes,
} from '@pedix-workspace/utils';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, delay } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
  OrderPrintType,
  getCartItemDetailsText,
  getCartItemSubtotal,
} from '@pedix-workspace/orders';
import { PixelService } from 'ngx-multi-pixel';

declare const dataLayer: any;

type PageTrackInfo = {
  title: string;
  location: string;
  path: string;
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  pageTrack = new ReplaySubject<PageTrackInfo>(10);
  fbqInstance: any;
  gtagInstance: any;
  gtmScript: HTMLScriptElement;

  establishmentSlug: string;
  establishmentId: string;
  establishmentCurrency: any;

  private platformId = inject(PLATFORM_ID);
  private document = inject(DOCUMENT);

  private titleService = inject(Title);
  private router = inject(Router);
  private pixel = inject(PixelService);

  constructor() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.initializeLocationTracking();
  }

  gtag: any = (...args: any[]) => {
    if (this.gtagInstance) {
      this.gtagInstance(...args);
    }
  };

  initializeLocationTracking() {
    this.pageTrack.next({
      title: this.titleService.getTitle(),
      location: (window as any).location.href,
      path: (window as any).location.pathname,
    });

    return this.router.events
      .pipe(
        filter((e: any) => e instanceof NavigationEnd),
        map((e: NavigationEnd) => ({ path: e.urlAfterRedirects })),
        delay(0),
      )
      .subscribe(({ path }) => {
        this.pageTrack.next({
          title: this.titleService.getTitle(),
          location: (window as any).location.href,
          path,
        });
      });
  }

  initializeGoogleAnalyticsTracking(establishmentGoogleAnalyticsProperty: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (typeof (window as any).establishmentGtag === 'undefined') {
      console.log('GA script not available');
      return;
    }
    if (this.gtagInstance) {
      return;
    }
    // window.customGtag is initialized in index.html
    this.gtagInstance = (window as any).establishmentGtag;

    this.gtag('config', establishmentGoogleAnalyticsProperty, {
      send_page_view: false,
    });
    this.gtag('js', new Date());

    this.pageTrack.subscribe(({ title, location, path }) => {
      this.trackPageView(title, location, path);
    });
  }

  loadGoogleTagManagerScript(renderer: Renderer2, containerId: string) {
    if (this.gtmScript || isPlatformServer(this.platformId)) {
      return;
    }
    this.gtmScript = renderer.createElement('SCRIPT') as HTMLScriptElement;

    this.gtmScript.textContent = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='establishmentDataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','establishmentDataLayer','${containerId}');
    `;
    this.gtmScript.type = 'text/javascript';

    renderer.appendChild(this.document.head, this.gtmScript);
  }

  trackPageView(title: string, location: string, path: string) {
    this.pixel.track('PageView');
    this.gtag('event', 'page_view', {
      page_title: title,
      page_location: location,
      page_path: path,
    });
  }

  userLanded() {
    this.logEvent('user_landed');
  }

  listCategoryProducts(category: Pick<Category, 'name'>) {
    this.gtag('event', 'view_item_list');

    this.logEvent('click_category', {
      category_name: category.name,
    });
  }

  viewProduct(
    product: Pick<Product, 'id' | 'name' | 'price'>,
    category: Pick<Category, 'name'>,
    establishmentCategory: string,
  ) {
    this.pixel.track('ViewContent', {
      content_name: product.name,
      content_category: establishmentCategory,
      content_ids: [product.id],
      content_type: 'product',
      currency: this.establishmentCurrency,
      value: product.price,
    });
    this.gtag('event', 'view_item', {
      items: [
        {
          id: product.id,
          name: product.name,
          category: category.name,
          price: product.price,
        },
      ],
    });
    this.logEvent('view_item', {
      items: [
        {
          id: product.id,
          name: product.name,
          category: category.name,
          price: product.price,
        },
      ],
    });
  }

  addCartItem(cartItem: CartItem) {
    this.pixel.track('AddToCart', {
      content_name: cartItem.product.name,
      content_category: cartItem.category.name,
      content_ids: [cartItem.product.id],
      contents: this.getCartItemContents(cartItem),
      content_type: 'product',
      value: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
      currency: this.establishmentCurrency,
    });
    this.gtag('event', 'add_to_cart', {
      currency: this.establishmentCurrency,
      value: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
      items: [
        {
          id: cartItem.product.id,
          name: cartItem.product.name,
          category: cartItem.category.name,
          price: cartItem.product.price,
          quantity: cartItem.qty,
        },
      ],
    });
    this.logEvent('add_to_cart', {
      currency: this.establishmentCurrency,
      value: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
      items: [
        {
          id: cartItem.product.id,
          name: cartItem.product.name,
          category: cartItem.category.name,
          price: cartItem.product.price,
          quantity: cartItem.qty,
        },
      ],
    });
  }

  removeCartItem(cartItem: CartItem) {
    this.gtag('event', 'remove_from_cart', {
      currency: this.establishmentCurrency,
      value: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
      items: [
        {
          id: cartItem.product.id,
          name: cartItem.product.name,
          category: cartItem.category.name,
          price: cartItem.product.price,
          quantity: cartItem.qty,
        },
      ],
    });
    this.logEvent('remove_from_cart', {
      currency: this.establishmentCurrency,
      value: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
      items: [
        {
          id: cartItem.product.id,
          name: cartItem.product.name,
          category: cartItem.category.name,
          price: cartItem.product.price,
          quantity: cartItem.qty,
        },
      ],
    });
  }

  updateCartItem(oldCartItem: CartItem, newCartItem: CartItem) {
    this.pixel.track('CustomizeProduct');
  }

  initiateCheckout(cartItems: CartItem[], totalAmount: number) {
    this.pixel.track('InitiateCheckout', {
      content_category: 'product',
      content_ids: cartItems.map(cartItem => cartItem.product.id),
      contents: cartItems.map(this.getCartItemContents),
      num_items: cartItems.length,
      currency: this.establishmentCurrency,
      value: totalAmount,
    });
    this.gtag('event', 'begin_checkout', {
      currency: this.establishmentCurrency,
      value: totalAmount,
      items: cartItems.map(cartItem => ({
        id: cartItem.product.id,
        name: cartItem.product.name,
        category: cartItem.category.name,
        price: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
        quantity: cartItem.qty,
      })),
    });
    this.logEvent('begin_checkout', {
      currency: this.establishmentCurrency,
      value: totalAmount,
      items: cartItems.map(cartItem => ({
        id: cartItem.product.id,
        name: cartItem.product.name,
        category: cartItem.category.name,
        price: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
        quantity: cartItem.qty,
      })),
    });
  }

  purchase(establishmentSlug: string, endOrderDetails: EndOrderDetails) {
    this.pixel.track('Purchase', {
      content_category: 'product',
      content_ids: endOrderDetails.cartItems.map(cartItem => cartItem.product.id),
      contents: endOrderDetails.cartItems.map(this.getCartItemContents),
      num_items: endOrderDetails.cartItems.length,
      currency: this.establishmentCurrency,
      value: endOrderDetails.finalAmount,
    });
    this.gtag('event', 'purchase', {
      currency: this.establishmentCurrency,
      value: endOrderDetails.finalAmount,
      shipping: endOrderDetails.deliveryCost,
      transaction_id: endOrderDetails.id,
      items: endOrderDetails.cartItems.map(cartItem => ({
        id: cartItem.product.id,
        name: cartItem.product.name,
        category: cartItem.category.name,
        price: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
        quantity: cartItem.qty,
      })),
    });

    this.logEvent('purchase', {
      currency: this.establishmentCurrency,
      value: endOrderDetails.finalAmount,
      shipping: endOrderDetails.deliveryCost,
      transaction_id: endOrderDetails.id,
      items: endOrderDetails.cartItems.map(cartItem => ({
        id: cartItem.product.id,
        name: cartItem.product.name,
        category: cartItem.category.name,
        price: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
        quantity: cartItem.qty,
      })),
    });
  }

  getCartItemContents(cartItem: CartItem) {
    return [
      {
        id: cartItem.product.id,
        quantity: cartItem.qty,
        name: cartItem.product.name,
        details: getCartItemDetailsText(cartItem),
        subtotal: getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
      },
    ];
  }

  fetchAddressDetails(section?: string, user?: string) {
    this.logEvent('fetch_address_details', {
      section,
      user,
    });
  }

  // Login Events ----------------------------------------------------------------------------
  logEvent(type: 'login_clicked'): void;
  logEvent(type: 'login'): void;
  logEvent(type: 'login_password_forgot_attempted'): void;
  logEvent(type: 'login_password_forgot_cancelled'): void;
  logEvent(type: 'login_password_forgot_email_sent'): void;
  logEvent(type: 'login_password_forgot_confirmed'): void;

  // Admin Panel Events ----------------------------------------------------------------------
  // TODO: value could be more restrictive
  logEvent(type: 'adminpanel_option_clicked', data: { menu_option: AdminPanelOptionType }): void;
  logEvent(type: 'adminpanel_helpcenter_clicked', data: { device: 'mobile' | 'desktop' }): void;
  logEvent(
    type: 'adminpanel_linksnavigation_clicked',
    data: { lateral_menu: AdminPanelLinksNavigation },
  ): void;
  logEvent(
    type: 'adminpanel_headeroptions_clicked',
    data: { header_menu: AdminPanelHeaderNavigation },
  ): void;

  // Admin Establishment Events --------------------------------------------------------------
  logEvent(type: 'account_info_saved', data: { currency: string }): void;
  logEvent(type: 'account_info_cancelled'): void;

  // Admin Payment Methods Events ------------------------------------------------------------
  logEvent(type: 'payment_config_saved', data: { state: boolean; type: string }): void;
  logEvent(
    type: 'payment_config_custom_payment_added',
    data: { custom_payment_legend: boolean; price_modifier: PaymentMethodPriceModifier },
  ): void;
  logEvent(type: 'payment_config_custom_payment_removed'): void;
  logEvent(type: 'payment_config_cancelled'): void;

  // Admin Coupons Events --------------------------------------------------------------------
  logEvent(type: 'discount_coupon_add_attempted'): void;
  logEvent(
    type: 'discount_coupon_added',
    data: { coupon_type: string; coupon_quantity: string },
  ): void;

  // Admin Shipping Events -------------------------------------------------------------------
  logEvent(type: 'delivery_config_saved'): void;
  logEvent(type: 'delivery_config_cancelled'): void;

  // Admin Tracking Codes Events -------------------------------------------------------------
  logEvent(type: 'tracking_config_saved', data: { GA: boolean; facebook: boolean }): void;
  logEvent(type: 'tracking_config_cancelled'): void;

  // Admin Business Hours Events -------------------------------------------------------------
  logEvent(
    type: 'bizzhours_config_saved',
    data: { bizzhours_type: 'daily' | 'weekly'; bizzhours_type_enable_orders_on_closed: boolean },
  ): void;
  logEvent(type: 'bizzhours_config_cancelled'): void;

  // Admin QR Code Events --------------------------------------------------------------------
  logEvent(type: 'qr_establishment_downloaded'): void;
  logEvent(type: 'qr_menu_downloaded'): void;

  // Admin Checkout Config Events ------------------------------------------------------------
  logEvent(type: 'checkout_config_saved'): void;
  logEvent(type: 'checkout_config_cancelled'): void;
  logEvent(type: 'checkout_config_item_added'): void;
  logEvent(type: 'checkout_config_item_removed'): void;

  // Admin Social Media Events ---------------------------------------------------------------
  logEvent(type: 'sm_config_saved'): void;
  logEvent(type: 'sm_config_cancelled'): void;

  // Admin Theme Events ----------------------------------------------------------------------
  logEvent(type: 'colors_config_saved'): void;
  logEvent(type: 'colors_config_cancelled'): void;

  // Admin Reports Events ----------------------------------------------------------------------
  logEvent(type: 'statistics_filter_changed', data: { statistics_filter: string | number }): void;
  logEvent(type: 'statistics_option_clicked', data: { statistics_option: string }): void;
  logEvent(type: 'reports_view_clicked'): void;
  logEvent(type: 'reports_download_clicked'): void;
  logEvent(type: 'reports_type_changed', data: { reports_type: REPORT_TYPE }): void;
  logEvent(type: 'reports_filter_changed', data: { reports_filter: string | number | Date }): void;
  logEvent(type: 'statistics_type_changed', data: { statistics_type: StatisticsTypes }): void;
  logEvent(
    type: 'statistics_advanced_type',
    data: { statistic_advanced_type: StatisticsAdvancedTypes },
  ): void;

  // Admin Subscription Events ---------------------------------------------------------------
  logEvent(
    type: 'subscription_upgrade_clicked',
    data: { subscription_location: 'adminPanel' | 'subscription' },
  ): void;
  logEvent(
    type: 'subscription_upgrade_attempted',
    data: { plan_type: PlanType; billing_type: BillingType },
  ): void;
  logEvent(
    type: 'subscription_upgrade_confirmed',
    data: { plan_type: PlanType; billing_type: BillingType },
  ): void;
  logEvent(
    type: 'subscription_upgrade_cancelled',
    data: { plan_type: PlanType; billing_type: BillingType },
  ): void;
  logEvent(type: 'subscription_cancelled_reason', data: { cancelled_type: string }): void;
  logEvent(type: 'subscription_self_service', data: { gateway: PaymentGateway }): void;

  // Admin Orders Events ---------------------------------------------------------------------
  logEvent(type: 'orders_view_details'): void;
  logEvent(type: 'orders_search', data: { orders_search_type: 'id' | 'sequence' }): void;
  logEvent(type: 'orders_whatsapp'): void;
  logEvent(
    type: 'orders_print',
    data: {
      print_type: OrderPrintType;
      print_mode: 'auto' | 'manual';
      print_action?: 'click' | 'drag';
    },
  ): void;
  logEvent(type: 'orders_print_config', data: { print_config_type: 'bluetooth' | 'usb' }): void;

  logEvent(type: 'orders_update_status', data: { orders_status: string }): void;
  logEvent(type: 'orders_cancel', data: { cancel_reason: OrderCancelReason }): void;
  logEvent(type: 'orders_date_filter', data: { orders_date_filter_type: DateRangeKey }): void;
  logEvent(
    type: 'orders_applied_filters',
    data: {
      filter_client: boolean;
      filter_status: boolean;
      filter_tags: boolean;
      filter_payment_method: boolean;
      filter_payment_status: boolean;
      filter_shipping_method: boolean;
    },
  ): void;
  logEvent(type: 'notification_sound_selection', data: { selected_sound: string }): void;
  logEvent(
    type: 'notification_sound_repetition',
    data: { sound_repetition: 0 | 10000 | 30000 | 60000 },
  ): void;
  logEvent(
    type: 'notification_sound_toggle',
    data: { sound_toggle_state: 'activated' | 'deactivated' },
  ): void;
  logEvent(type: 'orders_update_toast_click', data: { toast_click_type: 'delay' | 'update' }): void;
  logEvent(
    type: 'orders_update_labels_notes',
    data: { orders_updated_elements: 'tags' | 'notes' | 'both' },
  ): void;

  // Help Events -----------------------------------------------------------------------------
  logEvent(
    type: 'adminpanel_help_navigated',
    data:
      | { adminpanel_type: 'admin'; adminpanel_step: string; adminpanel_accepted: boolean }
      | { adminpanel_type: 'catalogue'; adminpanel_step: string; adminpanel_accepted: boolean },
  ): void;

  // General App Events ----------------------------------------------------------------------
  logEvent(type: 'sidemenupedix_interest_clicked'): void;
  logEvent(type: 'sidemenu_item_clicked', data: { menu_option: string }): void;

  // Category Events -------------------------------------------------------------------------
  logEvent(type: 'cataloguecategory_add_attempted'): void;
  logEvent(type: 'catalogue_category_added', data: { category_name: string }): void;
  logEvent(type: 'catalogue_category_update_attempted'): void;
  logEvent(type: 'catalogue_category_updated', data: { category_state: boolean }): void;
  logEvent(type: 'catalogue_category_delete_attempted'): void;
  logEvent(type: 'catalogue_category_deleted'): void;
  logEvent(type: 'catalogue_category_delete_cancelled'): void;
  logEvent(type: 'catalogue_category_sort_attempted'): void;
  logEvent(type: 'catalogue_category_sorted'): void;
  logEvent(type: 'category_hours_configure'): void;

  // Products Events -------------------------------------------------------------------------
  logEvent(type: 'product_add_attempted'): void;
  logEvent(type: 'product_added', data: { product_name: string }): void;
  logEvent(
    type: 'product_edit_attempted',
    data: { product_edit_location: 'normal' | 'preview' },
  ): void;
  logEvent(type: 'product_gallery_added', data: { product_gallery_quantity: number }): void;

  // Shared Options Events -------------------------------------------------------------------------
  logEvent(type: 'sharedoptions_save_attempted'): void;
  logEvent(type: 'sharedoptions_save_cancelled'): void;
  logEvent(type: 'sharedoptions_added'): void;
  logEvent(type: 'sharedoptions_item_save_attempted'): void;
  logEvent(type: 'sharedoptions_item_save_cancelled'): void;
  logEvent(type: 'sharedoptions_item_added'): void;

  // Legacy Captured Events --------------------- ---------------------------------------------
  logEvent(type: 'user_landed'): void;
  logEvent(type: 'click_category', data: { category_name: string }): void;
  logEvent(type: 'view_item', data: { items: unknown[] }): void;
  logEvent(type: 'add_to_cart', data: { currency: string; value: number; items: unknown[] }): void;
  logEvent(
    type: 'remove_from_cart',
    data: { currency: string; value: number; items: unknown[] },
  ): void;
  logEvent(
    type: 'begin_checkout',
    data: { currency: string; value: number; items: unknown[] },
  ): void;
  logEvent(type: 'fetch_address_details', data: { section?: string; user?: string }): void;

  // Integrations
  logEvent(type: 'integrations_disable_attempted'): void;
  logEvent(type: 'integrations_disabled'): void;
  logEvent(type: 'integrations_create_attempted'): void;
  logEvent(type: 'integrations_edit_attempted'): void;
  logEvent(type: 'integrations_saved', data: { integration_provider: string }): void;
  logEvent(
    type: 'purchase',
    data: {
      currency: string;
      value: number | undefined;
      shipping: number;
      transaction_id: string | undefined;
      items: unknown[];
    },
  ): void;

  logEvent(
    type: 'subsidiaries_orderby_clicked',
    data: { subsidiaries_order_by: 'city' | 'nearest' },
  ): void;
  logEvent(type: 'subsidiaries_orderby_error'): void;

  // Clone Events ----------------------------------------------------------------------------
  logEvent(type: 'clone_clonning'): void;

  // BulkEdit Events -------------------------------------------------------------------------
  logEvent(type: 'bulk_action_clicked', data: { bulk_action_type: BulkActionType }): void;
  logEvent(
    type: 'bulk_action_completed',
    data: { bulk_action_type: BulkActionType; bulk_action_success: boolean },
  ): void;
  logEvent(
    type: 'bulk_row_edit',
    data: { bulk_row_edit_type: 'price' | 'priceDiscount' | 'stockQty' },
  ): void;
  logEvent(type: 'bulk_export_download'): void;
  logEvent(type: 'bulk_empty_download'): void;
  logEvent(type: 'bulk_import_clicked'): void;
  logEvent(type: 'bulk_import_completed', data: { import_success: boolean }): void;

  // logEvent (implementation) ---------------------------------------------------------------
  logEvent(event: string, data?: Record<string, any>): void {
    if (isPlatformServer(this.platformId) || typeof dataLayer === 'undefined') {
      return;
    }
    try {
      dataLayer.push({
        event,
        establishment: this.establishmentSlug,
        establishmentId: this.establishmentId,
        ...data,
      });
    } catch (error) {
      console.warn('analytics.service::logEvent', error, event, data);
    }
  }
}
