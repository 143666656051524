import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EstablishmentOpenedStatus, TemporaryClosure, WorkingDays, getEstablishmentOpenedStatus, isEstablishmentAboutToClose, isEstablishmentOpened, isEstablishmentTemporaryClosed } from '@pedix-workspace/utils';
import { Observable, SchedulerLike, of, timer } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { ApiV1_Establishment } from '@pedix-workspace/api-v1';
import { AppRequestContextService } from '../../app-request-context.service';
import { runOutsideAngularScheduler } from '../../utils/zone-schedulers';

@UntilDestroy()
// NOTE: we do not inject this in "root" on purpose; it  should be provided in AppLayout component so it's  available for component tree
@Injectable()
export class EstablishmentWorkingHoursService {

  openedStatus$: Observable<EstablishmentOpenedStatus>;
  establishmentIsAboutToClose: boolean;
  establishmentTemporaryClosed: boolean;

  get establishment(): ApiV1_Establishment {
    return this.appRequestContextService.establishment;
  }

  private platformId = inject(PLATFORM_ID);
  
  private ngZone = inject(NgZone);
  private appRequestContextService = inject(AppRequestContextService);
  
  constructor() {
    this.openedStatus$ = this.isEstablishmentOpened$(this.establishment.workingDays, this.establishment.temporaryClosure, {
      isServer: isPlatformServer(this.platformId),
      scheduler: runOutsideAngularScheduler(this.ngZone),
    })
      .pipe(
        shareReplay({
          refCount: false,
          bufferSize: 1,
        }),
        untilDestroyed(this),
      );
  }

  private isEstablishmentOpened$(workingDays: WorkingDays | undefined, temporaryClosure: TemporaryClosure | undefined, options: { isServer: boolean, scheduler?: SchedulerLike }): Observable<EstablishmentOpenedStatus> {
    if (options.isServer) {
      // we cannot get server time accurately, as server time won't (necessarly) match client time
      return of(getEstablishmentOpenedStatus({
        isOpened: true,
        isAboutToClose: false,
        isTemporaryClosed: isEstablishmentTemporaryClosed(temporaryClosure),
      }, workingDays));
    }
  
    const secondsToMinuteOClock = 60 - new Date().getSeconds();
  
    return timer(secondsToMinuteOClock * 1000, 60000, options.scheduler)
      .pipe(
        map(() => getEstablishmentOpenedStatus({
          isOpened: isEstablishmentOpened(workingDays),
          isAboutToClose: isEstablishmentAboutToClose(workingDays),
          isTemporaryClosed: isEstablishmentTemporaryClosed(temporaryClosure),
        }, workingDays)),
        startWith(getEstablishmentOpenedStatus({
          isOpened: isEstablishmentOpened(workingDays),
          isAboutToClose: isEstablishmentAboutToClose(workingDays),
          isTemporaryClosed: isEstablishmentTemporaryClosed(temporaryClosure),
        }, workingDays)),
      );
  };
}