import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';

import { NgClass } from '@angular/common';
import { IconMinusSignComponent, IconPlusSignComponent } from '@pedix-workspace/angular-ui-icons';
import { InputNumberComponent } from '@pedix-workspace/angular-ui-form-reactive';
import { FormsModule } from '@angular/forms';

export type CounterSize = 'sm' | 'xl';
export type CounterColor = 'yellow' | 'orange';

@Component({
  selector: 'pxw-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    IconMinusSignComponent,
    IconPlusSignComponent,
    InputNumberComponent,
    FormsModule,
  ],
})
export class CounterComponent {
  @Input() size: CounterSize = 'xl';
  @Input() color: CounterColor = 'orange';
  @Input() inverted = false;
  @Input() count = 1;
  @Input() min = 1;
  @Input() max: number | undefined;
  @Input() disabled = false;
  @Input() decrementDisabled = false;
  @Input() incrementDisabled = false;
  @Output() countChange = new EventEmitter<number>();

  @HostBinding('class') get classes() {
    return [
      this.size,
      this.color,
      this.inverted ? 'inverted' : '',
      this.disabled ? 'disabled' : '',
    ].join(' ');
  }

  get iconSize() {
    return this.size === 'sm' ? 'xs' : 'sm';
  }

  increment($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (
      this.disabled ||
      this.incrementDisabled ||
      (this.max !== undefined && this.count >= this.max)
    ) {
      return;
    }

    this.countChange.emit(this.count + 1);
  }

  decrement($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (
      this.disabled ||
      this.decrementDisabled ||
      (this.min !== undefined && this.count <= this.min)
    ) {
      return;
    }

    this.countChange.emit(this.count - 1);
  }

  onValueChange(value: number) {
    this.countChange.emit(value);
  }

  onCounterClick($event: Event) {
    $event.stopPropagation();
  }
}
