// NOTE: Based ON: https://github.com/ftischler/ngx-rxjs-zone-scheduler/tree/main/libs/ngx-rxjs-zone-scheduler

import { NgZone } from '@angular/core';
import {
  asyncScheduler,
  MonoTypeOperatorFunction,
  SchedulerLike,
  Subscription,
} from 'rxjs';
import { subscribeOn } from 'rxjs/operators';

type Work<T> = (state?: T) => void;
type Delay = number | undefined;

abstract class ZoneScheduler implements SchedulerLike {
  constructor(protected ngZone: NgZone, protected scheduler: SchedulerLike) {}

  abstract schedule<T>(...args: [Work<T>, Delay, T]): Subscription;

  now(): number {
    return this.scheduler.now();
  }
}

class LeaveZoneScheduler extends ZoneScheduler {
  override schedule<T>(...args: [Work<T>, Delay, T]): Subscription {
    return this.ngZone.runOutsideAngular(() => {
      return this.scheduler.schedule(...args);
    });
  }
}

export function runOutsideAngularScheduler(
  ngZone: NgZone,
  scheduler: SchedulerLike = asyncScheduler
): SchedulerLike {
  return new LeaveZoneScheduler(ngZone, scheduler);
}
