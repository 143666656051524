import { EndOrderDetails } from '@pedix-workspace/utils';
import {
  EscPosPrinterParams,
  MessageEstablishmentData,
  MessageRenderParams,
  MessageRenderType,
  OrderPrintType,
} from './order-message.types';
import { generateOrderMessageDetailed } from './order-messages/order-message-detailed';
import { generateOrderMessageReceipt } from './order-messages/order-message-receipt';
import { generateOrderMessageKitchen } from './order-messages/order-message-kitchen';

export type GenerateOrderMessageParams = {
  rendererType: MessageRenderType;
  orderDetails: EndOrderDetails;
  establishmentData: MessageEstablishmentData;
  renderParams: MessageRenderParams;
  printerParams?: EscPosPrinterParams;
};

export const generateOrderMessage = (
  printType: OrderPrintType,
  orderMessageParams: GenerateOrderMessageParams,
) => {
  switch (printType) {
    case 'detailed':
      return generateOrderMessageDetailed(orderMessageParams);
    case 'receipt':
      return generateOrderMessageReceipt(orderMessageParams);
    case 'kitchen':
      return generateOrderMessageKitchen(orderMessageParams);
  }
};
