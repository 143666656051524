/*
 * @todo Explicitly referencing the barrel file seems to be necessary when enabling the
 * isolatedModules compiler option.
 */
export * from './interfaces/index';
export * from './types/index';
export const H = typeof window === 'object' ? window.H : undefined;
// tslint:disable-next-line:no-shadowed-variable
export const isHereMaps = H => {
  return typeof H === 'object' && H !== null && 'geo' in H && 'Map' in H && 'map' in H && 'math' in H && 'util' in H;
};
// tslint:disable-next-line:no-shadowed-variable
export const isHereMapsWithClusteringNamespace = H => {
  return isHereMaps(H) && 'clustering' in H;
};
// tslint:disable-next-line:no-shadowed-variable
export const isHereMapsWithDataNamespace = H => {
  return isHereMaps(H) && 'data' in H;
};
// tslint:disable-next-line:no-shadowed-variable
export const isHereMapsWithMapEventsNamespace = H => {
  return isHereMaps(H) && 'mapevents' in H;
};
// tslint:disable-next-line:no-shadowed-variable
export const isHereMapsWithServiceNamespace = H => {
  return isHereMaps(H) && 'service' in H;
};
// tslint:disable-next-line:no-shadowed-variable
export const isHereMapsWithUiNamespace = H => {
  return isHereMaps(H) && 'ui' in H;
};
