import {
  EstablishmentCheckout,
  TypesOfShippings,
  firestoreDateParser,
  Feature,
  DeliveryType,
  PaymentMethod,
} from '@pedix-workspace/utils';
import { FirestoreNestedConverter, withNestedConverter } from './utils';

export type EstablishmentCheckoutConverterParams = {
  nestedConverter?: FirestoreNestedConverter<EstablishmentCheckout>;
  features: Feature[];
};
export const DEFAULT_SHIPPING_OPTIONS: EstablishmentCheckout['shippingOptions'] = [
  TypesOfShippings.DELIVERY,
  TypesOfShippings.TAKE_AWAY,
];

export const getEstablishmentCheckoutConverter = ({
  nestedConverter,
  features,
}: EstablishmentCheckoutConverterParams) =>
  withNestedConverter({
    fromFirestore(snapshot) {
      return convertEstablishmentCheckoutData(
        snapshot.id,
        <EstablishmentCheckout>snapshot.data(),
        features,
      );
    },
    toFirestore(establishment: EstablishmentCheckout): EstablishmentCheckout {
      return establishment;
    },
  })(nestedConverter);

export const convertEstablishmentCheckoutData = (
  establishmentCheckoutId: string,
  establishmentCheckoutData: Partial<EstablishmentCheckout>,
  features: Feature[],
): EstablishmentCheckout => {
  return <EstablishmentCheckout>{
    ...establishmentCheckoutData,
    id: establishmentCheckoutId,
    deliveryCost:
      establishmentCheckoutData.deliveryCost === undefined
        ? 0
        : establishmentCheckoutData.deliveryCost,
    shippingOptions: establishmentCheckoutData.shippingOptions || DEFAULT_SHIPPING_OPTIONS,
    requireAddressDetails: getRequireAddressDetails(
      features,
      establishmentCheckoutData.requireAddressDetails,
    ),
    outOfDeliveryZoneShippingAllowed:
      establishmentCheckoutData.outOfDeliveryZoneShippingAllowed || false,
    deliveryType: getDeliveryType(features, establishmentCheckoutData.deliveryType),
    deliveryZones: establishmentCheckoutData.deliveryZones || [],
    paymentMethods: getPaymentMethods(establishmentCheckoutData.paymentMethods),
    customFields: getCustomFields(features, establishmentCheckoutData.customFields),
    created: firestoreDateParser(establishmentCheckoutData.created),
    updated: firestoreDateParser(establishmentCheckoutData.updated),
  };
};

export const getRequireAddressDetails = (
  features: Feature[],
  requireAddressDetails?: EstablishmentCheckout['requireAddressDetails'],
): EstablishmentCheckout['requireAddressDetails'] => {
  if (!features.includes('ADDRESS_IN_MAP')) {
    return false;
  }
  return requireAddressDetails || false;
};

export const getDeliveryType = (
  features: Feature[],
  deliveryType?: EstablishmentCheckout['deliveryType'],
): EstablishmentCheckout['deliveryType'] => {
  if (!features.includes('DELIVERY_ZONES')) {
    return DeliveryType.FIXED;
  }
  return deliveryType || DeliveryType.FIXED;
};

export const getPaymentMethods = (paymentMethods?: PaymentMethod[]) => {
  if (!paymentMethods) {
    return [];
  }
  return paymentMethods.map(paymentMethod => {
    if (!paymentMethod.priceModifier) {
      // Sets default priceModifier to 'none' and backfill existing records with 'extraCharge'
      paymentMethod.priceModifier = paymentMethod.extraCharge ? 'extraCharge' : 'none';
    }
    return paymentMethod;
  });
};

export const getCustomFields = (
  features: Feature[],
  customFields?: EstablishmentCheckout['customFields'],
): EstablishmentCheckout['customFields'] => {
  if (!customFields || !features.includes('CHECKOUT_CUSTOM_FIELDS')) {
    return [];
  }
  return customFields.map(customField => {
    if (!customField.fieldType) {
      customField.fieldType = 'text'; // default fieldType
    }
    if ((customField as any)['leyend']) {
      customField.legend = (customField as any)['leyend']; // fixes old data typo

      delete (customField as any)['leyend'];
    }
    return customField;
  });
};
