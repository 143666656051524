import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="whatsapp"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconWhatsappComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.1225 26.5731C30.6755 26.3496 28.484 25.2726 28.076 25.1226C27.668 24.9741 27.371 24.9006 27.0725 25.3476C26.7755 25.7916 25.922 26.7966 25.6625 27.0936C25.4015 27.3921 25.142 27.4281 24.6965 27.2061C24.251 26.9811 22.814 26.5116 21.1115 24.9936C19.787 23.8116 18.8915 22.3521 18.632 21.9051C18.3725 21.4596 18.6035 21.2181 18.827 20.9961C19.028 20.7966 19.2725 20.4756 19.496 20.2161C19.7195 19.9551 19.793 19.7691 19.9415 19.4706C20.0915 19.1736 20.0165 18.9141 19.904 18.6906C19.793 18.4671 18.902 16.2726 18.53 15.3801C18.1685 14.5116 17.801 14.6301 17.528 14.6151C17.267 14.6031 16.97 14.6001 16.673 14.6001C16.376 14.6001 15.893 14.7111 15.485 15.1581C15.0755 15.6036 13.925 16.6821 13.925 18.8766C13.925 21.0696 15.521 23.1891 15.7445 23.4876C15.968 23.7846 18.887 28.2876 23.3585 30.2181C24.4235 30.6771 25.253 30.9516 25.8995 31.1556C26.9675 31.4961 27.9395 31.4481 28.7075 31.3326C29.5625 31.2051 31.3445 30.2541 31.7165 29.2131C32.087 28.1721 32.087 27.2796 31.976 27.0936C31.865 26.9076 31.568 26.7966 31.121 26.5731H31.1225ZM22.9895 37.6776H22.9835C20.3276 37.678 17.7206 36.9641 15.4355 35.6106L14.8955 35.2896L9.2825 36.7626L10.781 31.2906L10.4285 30.7296C8.94371 28.3661 8.15794 25.6307 8.162 22.8396C8.165 14.6646 14.816 8.01356 22.9955 8.01356C26.9555 8.01356 30.6785 9.55856 33.4775 12.3606C34.8581 13.7354 35.9523 15.3704 36.6969 17.1709C37.4415 18.9714 37.8217 20.9017 37.8155 22.8501C37.8125 31.0251 31.1615 37.6776 22.9895 37.6776ZM35.6075 10.2321C33.9548 8.56845 31.9883 7.24937 29.8221 6.35126C27.6559 5.45316 25.333 4.99389 22.988 5.00006C13.157 5.00006 5.153 13.0026 5.15 22.8381C5.14544 25.9682 5.96656 29.0441 7.5305 31.7556L5 41.0001L14.456 38.5191C17.0718 39.9442 20.0031 40.691 22.982 40.6911H22.9895C32.8205 40.6911 40.8245 32.6886 40.8275 22.8516C40.8348 20.5075 40.3771 18.1852 39.4811 16.0191C38.5851 13.853 37.2685 11.8861 35.6075 10.2321Z"
    />
  </svg>`,
})
export class IconWhatsappComponent extends IconComponent {}
