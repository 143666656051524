import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

import { CfIpLocation } from '@pedix-workspace/utils';

export type GeoIpState = {
  lat: number;
  lng: number;
};

@Injectable({
  providedIn: 'root',
})
export class GeoIpService {
  private geoIpStatePromise: Promise<GeoIpState | null>;

  private platformId = inject(PLATFORM_ID);

  async retrieveGeoIpState(apiUrl: string): Promise<GeoIpState | null> {
    if (isPlatformServer(this.platformId)) {
      return Promise.resolve(null);
    }
    if (this.geoIpStatePromise) {
      return this.geoIpStatePromise;
    }

    const response = await fetch(`${apiUrl}/ip-location`);

    if (response.ok) {
      const ipLocation = await response.json().then(data => <CfIpLocation>data);
      const geoIpState: GeoIpState = {
        lat: Number(ipLocation.latitude),
        lng: Number(ipLocation.longitude),
      };

      this.geoIpStatePromise = Promise.resolve(geoIpState);
    } else {
      this.geoIpStatePromise = Promise.resolve(null);
    }
    return this.geoIpStatePromise;
  }
}
