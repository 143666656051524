import { CurrencyLocaleData } from "./types";

export type CurrencyFormatOptions = {
  forceDecimals?: boolean;
  separateSymbol?: boolean;
  roundAmount?: boolean;
  skipSymbol?: boolean;
};

export function currencyFormat(
  amount: number,
  { currencySymbol, thousandsSeparator, decimalSeparator, decimalsLength }: CurrencyLocaleData,
  { forceDecimals, separateSymbol, roundAmount, skipSymbol }: CurrencyFormatOptions = {},
): string {
  const isNegative = amount < 0;
  const parsedAmount = roundAmount ? parseInt(amount.toFixed(0)) : amount;
  const amountString = (Math.abs(parsedAmount)).toString();
  const [intAmount, floatAmount = ''] = amountString.split('.');
  const formattedRoundAmount = intAmount
    .split('')
    .reverse()
    .reduce((formattedAmount, digit, index) => {
      if (index > 0 && index % 3 === 0) {
        return `${digit}${thousandsSeparator}${formattedAmount}`;
      }
      return `${digit}${formattedAmount}`;
    }, '');

  let formattedDecimals;

  if (forceDecimals && decimalsLength > 0) {
    formattedDecimals = floatAmount.padEnd(decimalsLength, '0').slice(0, decimalsLength);
  } else {
    formattedDecimals = floatAmount !== '' ? floatAmount.padEnd(decimalsLength, '0').slice(0, decimalsLength) : '';
  }

  const formattedSymbol = skipSymbol ? '' : separateSymbol ? `${currencySymbol} ` : currencySymbol;

  if (formattedDecimals) {
    return `${isNegative ? '-' : ''}${formattedSymbol}${formattedRoundAmount}${decimalSeparator}${formattedDecimals}`;
  }
  return `${isNegative ? '-' : ''}${formattedSymbol}${formattedRoundAmount}`;
}
