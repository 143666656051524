import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@pedix-workspace/utils';
import slugify from 'slugify';

export type ProductsByGroupName<T> = Array<{
  id: string;
  groupName: string;
  listIndex?: number;
  products: T[];
}>;

export type ProductsByGroupNameAndIndexed<T> = {
  id: string;
  groupName: string;
  listIndex?: number;
  indexedProducts: {
    listIndex: number;
    product: T;
  }[];
}[];

@Pipe({
  name: 'pxwProductsByGroupName',
  standalone: true,
})
export class ProductsByGroupNamePipe<T> implements PipeTransform {
  transform(
    productsList: (T & Pick<Product, 'groupName'>)[],
    undefinedGroupName = '',
  ): ProductsByGroupNameAndIndexed<T> {
    const productsByGroupName: Record<string, T[]> = {};
    const otherProducts: T[] = [];

    productsList.forEach((product, index) => {
      const groupName = product.groupName;

      if (groupName) {
        if (!productsByGroupName[groupName]) {
          productsByGroupName[groupName] = [];
        }
        productsByGroupName[groupName].push(product);
      } else {
        otherProducts.push(product);
      }
    });

    const productGroups: ProductsByGroupName<T> = Object.entries(productsByGroupName).map(
      ([groupName, products]) => ({
        id: slugify(groupName),
        groupName,
        products,
      }),
    );

    if (otherProducts.length > 0) {
      productGroups.push({
        id: 'others',
        groupName: undefinedGroupName,
        products: otherProducts,
      });
    }

    let productIndex = 0;

    return productGroups.map((productGroup, index) => ({
      ...productGroup,
      listIndex: index,
      indexedProducts: productGroup.products.map((product: T) => ({
        listIndex: productIndex++,
        product,
      })),
    }));
  }
}
