import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { InputRadioGroupValueType } from './input-radio-group.component';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'radioIsDisabled',
  standalone: true,
})
export class RadioIsDisabledPipe implements PipeTransform {
  transform(option: any, valueExpr: string, disabledOptions?: InputRadioGroupValueType): boolean {
    return (disabledOptions || []).includes(option[valueExpr]);
  }
}
