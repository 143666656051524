import { Pipe, PipeTransform } from '@angular/core';
import {
  DEFAULT_LANGUAGE,
  getPropInSelectedLanguage,
  SUPPORTED_LANGUAGES,
} from '@pedix-workspace/utils';

@Pipe({
  name: 'propInSelectedLanguage',
  standalone: true,
})
export class PropInSelectedLanguagePipe implements PipeTransform {
  transform<T extends object>(
    value: T,
    prop: keyof T,
    selectedLanguage: SUPPORTED_LANGUAGES = DEFAULT_LANGUAGE,
    isRequired = false,
  ): string {
    return getPropInSelectedLanguage(value, prop, selectedLanguage, isRequired);
  }
}
