export const DAY_OF_WEEK_NAMES = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};

export function formatDaysOfWeek(daysOfWeek: number[]): string {
  const ranges: number[][] = [];

  daysOfWeek.forEach(current => {
    if (ranges.length === 0) {
      ranges.push([current]);
    } else {
      const lastRange = ranges[ranges.length - 1];
      const lastValue = lastRange[lastRange.length - 1];

      if (lastValue + 1 === current) {
        lastRange.push(current);
      } else {
        ranges.push([current]);
      }
    }
  });

  const rangesDays: string[] = [];

  ranges.forEach(range => {
    const days = range.reduce((arr, current) => {
      arr.push(DAY_OF_WEEK_NAMES[current]);

      return arr
    }, []);

    if (days.length > 2) {
      rangesDays.push(days[0] + ' a ' + days[days.length - 1]);
    } else if (days.length === 2) {
      rangesDays.push(days.join(' y '));
    } else {
      rangesDays.push(days[0]);
    }
  });

  let daysText: string;

  if (rangesDays.length > 2) {
    daysText = rangesDays.slice(0, -1).join(', ') + ' y ' + rangesDays[rangesDays.length - 1];
  } else if (rangesDays.length === 2) {
    daysText = rangesDays.join(' y ');
  } else {
    daysText = rangesDays[0];
  }

  const lastYPos = daysText.lastIndexOf(' y ');

  return `${daysText.substring(0, lastYPos).replace(/ y /g, ', ')}${daysText.substring(lastYPos)}`
}

export function formatHour(militaryHour: number) {
  if (militaryHour > 2400) {
    militaryHour -= 2400;
  }
  const amPm = militaryHour < 1200 ? 'AM' : 'PM';
  const hourString = String(militaryHour).padStart(4, '0');

  return `${hourString.substr(0, 2)}:${hourString.substr(2, 2)}${amPm}`;
}