import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  prefix = 'pedixapp';
  memoryStorage = new Map<string, string>();

  private platformId = inject(PLATFORM_ID);
  
  setItem(key: string, value: any, namespace: string | undefined): void {
    if (isPlatformServer(this.platformId)) {
      this.memoryStorage.set(this.getPrefixedKey(key, namespace), JSON.stringify(value));
    } else {
      window.sessionStorage.setItem(this.getPrefixedKey(key, namespace), JSON.stringify(value));
    }
  }

  getItem(key: string, namespace: string | undefined): any {
    let value: string | undefined | null;

    if (isPlatformServer(this.platformId)) {
      value = this.memoryStorage.get(this.getPrefixedKey(key, namespace));
    } else {
      value = window.sessionStorage.getItem(this.getPrefixedKey(key, namespace));
    }

    return value ? JSON.parse(value) : value;
  }

  hasItem(key: string, namespace: string | undefined): boolean {
    if (isPlatformServer(this.platformId)) {
      return Boolean(this.memoryStorage.get(this.getPrefixedKey(key, namespace)));
    }
    return Boolean(window.sessionStorage.getItem(this.getPrefixedKey(key, namespace)));
  }

  private getPrefixedKey(key: string, namespace: string | undefined): string {
    return namespace ? `${this.prefix}-${namespace}-${key}` : `${this.prefix}-${key}`;
  }
}
