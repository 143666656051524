<ng-template #modalContentWrapper let-modal>
  <div class="dialog-heading">
    @if (icon) {
      <span class="heading-icon">
        <ng-content select="pxw-icon"></ng-content>
      </span>
    }
    @if (showCloseButton && !loading) {
      <pxw-icon class="close ui-clickable" icon="close" (click)="dismiss('close')"></pxw-icon>
    }
    @if (title) {
      <p class="title">{{ title }}</p>
    }
  </div>
  <div class="dialog-content">
    <ng-container *ngTemplateOutlet="modalContent"></ng-container>

    <ng-content></ng-content>
  </div>
  <div class="dialog-buttons {{ type }}">
    @if (modalButtons) {
      <ng-container *ngTemplateOutlet="modalButtons; context: { close }"></ng-container>
    } @else if (type === 'alert') {
      <pxw-button
        class="button action-button"
        color="pink"
        size="md"
        [rounded]="true"
        [loading]="loading"
        [disabled]="confirmDisabled"
        (click)="onConfirmAction()"
      >
        {{ acceptText }}
      </pxw-button>
    } @else if (type === 'confirm') {
      <pxw-button
        class="button neutral-button"
        appareance="outline"
        [rounded]="true"
        color="pink"
        size="md"
        [disabled]="loading"
        (click)="dismiss('cancel')"
      >
        {{ cancelText }}
      </pxw-button>
      <pxw-button
        class="button action-button"
        color="pink"
        size="md"
        [rounded]="true"
        [loading]="loading"
        [disabled]="confirmDisabled"
        (click)="onConfirmAction()"
      >
        {{ confirmText }}
      </pxw-button>
    }
  </div>
  <pxw-modal-history
    name="modal-dialog"
    [title]="title"
    (popState)="dismiss('close')"
  ></pxw-modal-history>
</ng-template>
