import { marker as _ } from '@ngneat/transloco-keys-manager/marker.js';
import { OrderItem, OrderPresentationParams, OrderOptionParams } from '../order-message.types';
import {
  OrderHeadingParams,
  OrderItemsByCategories,
  MessageRenderParams,
} from '../order-message.types';
import { translate } from '@pedix-workspace/utils';
import {
  endOrderToOrderHeadingParams,
  endOrderToOrderItemsByCategories,
} from '../order-message-utils/order-message-utils';
import { OrderMessageRendererEscPos } from '../order-message-renderer/order-message-renderer-escpos.class';
import { OrderMessageRendererBase } from '../order-message-renderer/order-message-renderer-base.class';
import * as dateFnsTz from 'date-fns-tz';
import { OrderMessageRendererWhatsapp } from '../order-message-renderer/order-message-renderer-whatsapp.class';
import { OrderMessageRendererHtml } from '../order-message-renderer/order-message-renderer-html.class';
import { GenerateOrderMessageParams } from '../generate-order-message';

export function generateOrderMessageKitchen({
  rendererType,
  orderDetails,
  renderParams,
  printerParams,
}: GenerateOrderMessageParams) {
  const orderHeadingParams = endOrderToOrderHeadingParams(orderDetails, renderParams.siteUrl);
  const orderItemsByCategories = endOrderToOrderItemsByCategories(orderDetails.cartItems);

  let renderer: OrderMessageRendererBase;

  switch (rendererType) {
    case 'whatsapp':
      renderer = new OrderMessageRendererWhatsapp(renderParams);
      break;
    case 'html':
      renderer = new OrderMessageRendererHtml(renderParams);
      break;
    case 'escpos':
      renderer = new OrderMessageRendererEscPos(renderParams, printerParams);
      break;
  }

  renderOrderHeading(renderer, orderHeadingParams, renderParams);
  renderEmptyLine(renderer);
  renderOrderItems(renderer, orderItemsByCategories, renderParams);
  renderEmptyLine(renderer);

  return renderer.output();
}

type NodeRenderer<T = unknown, R = MessageRenderParams> = {
  (renderer: OrderMessageRendererBase, options?: T, renderOptions?: R): void;
};

export const renderEmptyLine: NodeRenderer = renderer => renderer.renderEmptyLine();

export const renderOrderHeading: NodeRenderer<OrderHeadingParams> = (
  renderer,
  { id, sequenceId, date, name, shippingOptionType },
  { translations, timezone },
) => {
  const formattedDate = timezone
    ? dateFnsTz.formatInTimeZone(date, timezone, 'dd/MM/yy - HH:mm')
    : dateFnsTz.format(date, 'dd/MM/yy - HH:mm');

  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.orderTitle')),
    keyModifiers: { bold: true },
    valueText: `#${sequenceId} / ${id}`,
    valueModifiers: { monospace: true },
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.orderDate')),
    keyModifiers: { bold: true },
    valueText: `${formattedDate}hs`,
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.nameTitle')),
    keyModifiers: { bold: true },
    valueText: name,
  });

  if (shippingOptionType) {
    const shippingOptionLabel = translate(translations, _('orderMessage.shippingOptionTitle'));
    let shippingOptionValue: string;

    switch (shippingOptionType) {
      case 'DELIVERY':
        shippingOptionValue = translate(translations, _('orderMessage.shippingDeliveryValue'));
        break;
      case 'IN_PLACE':
        shippingOptionValue = translate(translations, _('orderMessage.shippingInPlaceTitle'));
        break;
      case 'TAKE_AWAY':
        shippingOptionValue = translate(translations, _('orderMessage.shippingTakeAwayValue'));
        break;
    }

    renderer.renderKeyValueLine({
      keyText: shippingOptionLabel,
      keyModifiers: { bold: true },
      valueText: shippingOptionValue,
    });
  }
};

export const renderOrderItems: NodeRenderer<OrderItemsByCategories> = (
  renderer,
  { categories },
  renderOptions,
) => {
  categories.forEach(category => {
    const isLastCategory = category === categories[categories.length - 1];

    category.groups.forEach(categoryGroup => {
      const isLastGroup = categoryGroup === category.groups[category.groups.length - 1];

      renderCategory(
        renderer,
        {
          categoryName: category.categoryName,
          groupName: categoryGroup.isCustomGroup ? categoryGroup.name : undefined,
          products: categoryGroup.items,
        },
        renderOptions,
      );
      if (!isLastCategory || !isLastGroup) {
        renderEmptyLine(renderer);
      }
    });
  });
};

export const renderCategory: NodeRenderer<{
  categoryName: string;
  groupName: string;
  products: OrderItem[];
}> = (renderer, { categoryName, groupName, products }, renderOptions) => {
  const title = groupName ? `${categoryName} (${groupName})` : categoryName;

  renderer.renderLine(title, { bold: true });

  products.forEach(product => renderProduct(renderer, product, renderOptions));
};

export const renderProduct: NodeRenderer<OrderItem> = (
  renderer,
  {
    productName,
    presentationName,
    productOrPresentationSku,
    quantity,
    observations,
    presentations,
    options,
  },
  renderOptions,
) => {
  renderer.renderLine(() => {
    if (quantity && !presentations) {
      renderer.renderText(`${quantity}x `);
    }
    if (productOrPresentationSku && renderOptions.displaySku) {
      renderer.renderText(productOrPresentationSku, { bold: true });
      renderer.renderText(' | ');
    }
    if (presentationName) {
      renderer.renderText(`${productName} (${presentationName})`);
    } else {
      renderer.renderText(productName);
    }
  });

  if (presentations && presentations?.items.length > 0) {
    renderProductPresentations(renderer, presentations, renderOptions);
  }
  if (options && options.length > 0) {
    renderProductOptions(renderer, options, { ...renderOptions, nestingLevel: 1 });
  }
  if (observations) {
    renderProductObservations(renderer, { observations }, { ...renderOptions, nestingLevel: 1 });
  }
};

export const renderProductPresentations: NodeRenderer<OrderPresentationParams> = (
  renderer,
  presentations,
  { displaySku },
) => {
  presentations.items.forEach(presentation => {
    renderer.renderText(`${presentation.quantity}x `);

    if (presentation.sku && displaySku) {
      renderer.renderText(presentation.sku, { bold: true });
      renderer.renderText(' | ');
    }
    renderer.renderText(presentation.name);

    renderer.renderEmptyLine();
  });
};

export const renderProductOptions: NodeRenderer<OrderOptionParams[]> = (
  renderer,
  options,
  { nestingLevel },
) => {
  options.forEach(option => {
    const optionTitle = `${renderer.getIdentationByLevel(nestingLevel)}${option.name}:`;

    renderer.renderLine(optionTitle);

    option.items.forEach(item => {
      let optionItemOutput = `${renderer.getIdentationByLevel(nestingLevel)}`;
      optionItemOutput += `- ${item.quantity}x ${item.name}`;

      renderer.renderLine(optionItemOutput);
    });
  });
};

export const renderProductObservations: NodeRenderer<{
  observations: string;
}> = (renderer, { observations }, { nestingLevel }) => {
  renderer.renderObservations(observations, { indentLevel: nestingLevel });
};
